/* eslint-disable */
import React, { useState } from 'react';
import { Flex, Text } from '../../../styles/common.styles';
import styled from 'styled-components';
import { url } from '../../../api';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../helpers/shopCart';
import useShipmentCart from '../../../../hooks/useShippment';
import { useEffect } from 'react';
// const desplegar = `${url}drop_down.svg`;
import desplegar from '../assets/skybluedownarrow.svg';

const ItemCard = ({ index, item, subTotal, countItems, shipment }) => {
  const { shippmentsAvailables } = useShipmentCart();
  const sub = item.reduce(
    (accumulator, currentValue) =>
      accumulator + stringToNumber(currentValue.price) * currentValue.qty,
    0
  );
  const count = item.reduce(
    (accumulator, currentValue) => accumulator + currentValue.qty,
    0
  );
  const [expandable, setExpandable] = useState(false);
  // const [shipment, setShipment] = useState('0.00');

  return (
    <>
      <Flex width='100%' height='fit-content' align='center' direction='column'>
        <Flex
          width='100%'
          padding='16px'
          height='auto'
          align='center'
          gap='16px'
          direction='row'
          bg='#EFF3F8'
          justify='space-between'
        >
          <Text
            color='#32b7d7'
            family='Mulish'
            size='24px'
            weight='700'
            width='fit-content'
            mobile='font-size: 16px;'
          >
            Paquete {index + 1}
          </Text>
          <Flex
            width='fit-content'
            height='auto'
            align='center'
            direction='row'
            gap='16px'
          >
            {/* {expandable ? (
              <Text
                color='#32B7D7'
                family='Inter'
                size='16px'
                width='fit-content'
              >
                ocultar detalle
              </Text>
            ) : ( */}
            <Flex
              width='fit-content'
              height='auto'
              align='center'
              direction='row'
              gap='16px'
              wrap
            >
              <Text color='#32B7D7' family='Inter' size='16px'>
                {expandable ? 'Ocultar detalles' : 'Ver detalle'}
              </Text>

              {/* <Text family='Roboto' color='#0E79B4' size='18px' weight='700'>
                ${formatNumberWithTwoDecimals(sub + stringToNumber(shipment))}{' '}
                MXN
              </Text> */}
            </Flex>
            {/* // )} */}

            <img
              src={desplegar}
              alt=''
              onClick={() => setExpandable(!expandable)}
              style={{
                transform: expandable ? 'rotate(180deg)' : 'rotate(0deg)',
                cursor: 'pointer',
              }}
            />
          </Flex>
        </Flex>
        {expandable ? (
          <Flex
            height='100%'
            width='100%'
            padding='16px 32px'
            gap='16px'
            direction='column'
            bg='rgba(239, 243, 248, 0.50)'
            // align='center'
          >
            <Text
              color='#0D3564'
              family='Inter'
              size='16px'
              weight='700'
              width='fit-content'
            >
              Productos
            </Text>
            <Flex width='100%' extras='overflow: auto; padding:0 0 32px;'>
              <table
                style={{
                  borderCollapse: 'collapse',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <thead>
                  <tr>
                    <StyledTh>Descripción</StyledTh>
                    <StyledTh style={{ textAlign: 'center' }}>
                      Cantidad
                    </StyledTh>
                    <StyledTh>No. Parte</StyledTh>
                    <StyledTh>Precio</StyledTh>
                  </tr>
                </thead>
                <tbody>
                  {item.map((e) => (
                    <tr>
                      <StyledTd>-{e.name}</StyledTd>
                      <StyledTd style={{ textAlign: 'center' }}>
                        {e.qty}
                      </StyledTd>
                      <StyledTd>{e.partNumber}</StyledTd>
                      <StyledTd>
                        ${formatNumberWithTwoDecimals(stringToNumber(e.price))}{' '}
                        MXN
                      </StyledTd>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Flex>
            <div
              style={{
                width: '90%',
                height: '1px',
                background: '#DCDEDE',
                alignSelf: 'center',
              }}
            />
            <Flex width='100%' align='flex-end' direction='column' gap='8px'>
              <Flex direction='row' gap='16px'>
                <Text family='Inter' size='14px' weight='400' color='#55646A'>
                  Subtotal ({count} productos):
                </Text>
                <Text
                  family='Mulish'
                  size='16px'
                  weight='700'
                  color='#55646A'
                  width='150px'
                  textAlign='right'
                >
                  ${formatNumberWithTwoDecimals(sub)} MXN
                </Text>
              </Flex>
              <Flex direction='row' gap='16px' justify='space-between'>
                <Text family='Inter' size='14px' weight='400' color='#55646A'>
                  Envío:
                </Text>
                <Text
                  family='Mulish'
                  size='16px'
                  weight='700'
                  color='#55646A'
                  width='150px'
                  textAlign='right'
                >
                  ${formatNumberWithTwoDecimals(stringToNumber(shipment))} MXN
                </Text>
              </Flex>
              <Flex direction='row' gap='16px'>
                <Text family='Inter' size='16px' weight='700' color='#022741'>
                  Subtotal:
                </Text>
                <Text
                  family='Inter'
                  size='18px'
                  weight='700'
                  color='#0E79B4'
                  width='150px'
                  textAlign='right'
                >
                  ${formatNumberWithTwoDecimals(sub + stringToNumber(shipment))}{' '}
                  MXN
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};

export default ItemCard;

const StyledTh = styled.th`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #0d3564;
  text-align: left;
  padding: 4px;
  white-space: nowrap;
`;

const StyledTd = styled.td`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #55646a;
  padding: 4px;
  width: fit-content;
  white-space: nowrap;
`;
