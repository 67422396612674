import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 80%;
  }

  @media (min-width: 601px) and (max-width: 768px) {
    width: 60%;
  }

  @media (min-width: 769px) {
    width: 40%;
  }
`;

const CloseButton = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-style: solid;
  background-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  &:disabled {
    background: rgb(229, 229, 229);
    color: black;
    border: 1px solid rgb(229, 229, 229);
    cursor: not-allowed;
  }

  @media (max-width: 1439px) {
    width: 100%;
  }
`;

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <ModalOverlay>
            <ModalContent>
                {children}
                <CloseButton onClick={() => onClose(false)}>Cerrar</CloseButton>
            </ModalContent>
        </ModalOverlay>
    );
};

export default Modal;