/* eslint-disable */
import React, { useState } from 'react';
import { Flex, Text } from '../../../styles/common.styles';
import styled from 'styled-components';
import { url } from '../../../api';
import { useEffect } from 'react';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../helpers/shopCart';
import desplegar from '../../../assets/dropdownlightblue.svg';

const ExpandableCard = ({ item, selectedShipment }) => {
  return (
    <Flex
      height='100%'
      width='100%'
      padding='32px'
      gap='16px'
      direction='row'
      bg='rgba(239, 243, 248, 0.50)'
      mobile='padding: 16px;'
    >
      <ImgContainer>
        <ImgStyled alt='' src={item.img} />
      </ImgContainer>
      <Flex width='100%' direction='column' gap='16px'>
        <Text
          family='Inter'
          size='16px'
          color='#55646A'
          width='100%'
          extras='max-height: 71px; overflow: hidden; text-overflow: ellipsis;'
        >
          {item.description}
        </Text>
        <Flex
          direction='row'
          align='flex-start'
          width='100%'
          gap='16px'
          mobile='flex-direction: column;'
        >
          <Flex direction='column' align='flex-start' width='100%' gap='16px'>
            <Flex direction='row' justify='flex-start' gap='8px' width='100%'>
              <Text
                maxWidth='180px'
                width='100%'
                color='#55646A'
                family='Inter'
                size='14px'
              >
                Cantidad:{' '}
              </Text>
              <Text
                maxWidth='180px'
                width='100%'
                color='#0E79B4'
                family='Inter'
                size='16px'
                weight='400'
              >
                {item.qty}
              </Text>
            </Flex>
            <Flex direction='row' justify='flex-start' gap='8px' width='100%'>
              <Text
                maxWidth='180px'
                width='100%'
                color='#55646A'
                family='Inter'
                size='14px'
              >
                No. Parte:{' '}
              </Text>
              <Text
                maxWidth='180px'
                width='100%'
                color='#0E79B4'
                family='Inter'
                size='16px'
                weight='400'
              >
                {item.partNumber}
              </Text>
            </Flex>
          </Flex>
          <Flex direction='column' align='flex-start' width='100%' gap='16px'>
            <Flex direction='row' justify='flex-start' gap='8px' width='100%'>
              <Text
                maxWidth='180px'
                width='100%'
                color='#55646A'
                family='Inter'
                size='14px'
              >
                Envío:{' '}
              </Text>
              <Text
                maxWidth='180px'
                width='100%'
                color='#0E79B4'
                family='Inter'
                size='16px'
                weight='400'
              >
                {selectedShipment.costo}
              </Text>
            </Flex>
            <Flex direction='row' justify='flex-start' gap='8px' width='100%'>
              <Text
                maxWidth='180px'
                width='100%'
                color='#55646A'
                family='Inter'
                size='14px'
              >
                Precio:{' '}
              </Text>
              <Text
                maxWidth='180px'
                width='100%'
                color='#0E79B4'
                family='Inter'
                size='16px'
                weight='700'
              >
                {item.price} MXN
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const GeneralInfo = ({ selectedShipment, items }) => {
  const [subTotal, setSubTotal] = useState(0);
  const [countItems, setCountItems] = useState(0);
  useEffect(() => {
    const sub = items.reduce(
      (accumulator, currentValue) =>
        accumulator + stringToNumber(currentValue.price) * currentValue.qty,
      0
    );
    const count = items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.qty,
      0
    );
    setSubTotal(sub);
    setCountItems(count);
  }, [items]);
  return (
    <>
      <Flex
        direction='row'
        width='100%'
        justify='space-between'
        padding='32px 0'
        align='center'
        mobile='flex-direction: column; align-items: flex-start;'
        gap='16px'
      >
        <Flex
          direction='row'
          width='100%'
          gap='16px'
          mobile='flex-direction: column; align-items: flex-start;'
        >
          <Text
            maxWidth='180px'
            width='100%'
            family='Mulish'
            size='16px'
            weight='700'
            color='#0D3564'
          >
            {/* Paqueteria */}
            {selectedShipment.nombre}
          </Text>
          <Text
            maxWidth='180px'
            width='100%'
            family='Mulish'
            size='16px'
            weight='700'
            color='#0D3564'
          >
            {/* Tipo */}
            {selectedShipment.servicio}
          </Text>
          <Text
            maxWidth='180px'
            width='100%'
            family='Mulish'
            size='16px'
            weight='700'
            color='#0D3564'
          >
            {/* tiempo de entrega */}({selectedShipment.dias_de_entrega} días
            hábiles)
          </Text>
        </Flex>
        <Divider />
        <Flex direction='column' gap='4px' width='100%'>
          <Flex direction='row' justify='flex-end' gap='8px' width='100%'>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#55646A'
              family='Inter'
              size='16px'
            >
              Subtotal ({countItems} productos):{' '}
            </Text>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#55646A'
              family='Mulish'
              size='16px'
              weight='700'
            >
              ${formatNumberWithTwoDecimals(subTotal)} MXN
            </Text>
          </Flex>
          <Flex direction='row' justify='flex-end' gap='8px' width='100%'>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#55646A'
              family='Inter'
              size='14px'
            >
              Envío:
            </Text>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#55646A'
              family='Inter'
              size='14px'
            >
              {selectedShipment.costo}
            </Text>
          </Flex>
          <Flex direction='row' justify='flex-end' gap='8px' width='100%'>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#022741'
              family='Inter'
              size='16px'
              weight='700'
            >
              Subtotal:{' '}
            </Text>
            <Text
              maxWidth='180px'
              width='100%'
              mobile='text-align: right;'
              color='#0E79B4'
              family='Inter'
              size='18px'
              weight='700'
            >
              ${formatNumberWithTwoDecimals(subTotal + stringToNumber(selectedShipment.costo))}{' '}MXN
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const ExpandableCardSuccess = ({ items, selectedShipment, index }) => {
  const [expandable, setExpandable] = useState(false);

  return (
    <>
      <Flex
        width='100%'
        height='fit-content'
        align='center'
        direction='column'
        bg='#ffffff'
        padding='8px 32px'
        mobile='padding: 8px 16px;'
        gap='8px'
      >
        <Flex
          width='100%'
          height='auto'
          align='center'
          gap='16px'
          direction='row'
          bg='#ffffff'
          justify='space-between'
        >
          <Text
            color='#32B7D7'
            family='Inter'
            size='18px'
            weight='700'
            width='fit-content'
          >
            Paquete {index + 1}
          </Text>
          <Flex
            width='fit-content'
            height='auto'
            align='center'
            direction='row'
            gap='16px'
          >
            {expandable ? (
              <Text
                color='#32B7D7'
                family='Inter'
                size='16px'
                width='fit-content'
              >
                ocultar detalle
              </Text>
            ) : (
              <Text
                color='#32B7D7'
                family='Inter'
                size='16px'
                width='fit-content'
              >
                Ver detalle
              </Text>
            )}
            <img
              src={desplegar}
              alt=''
              onClick={() => setExpandable(!expandable)}
              style={{
                transform: expandable ? 'rotate(180deg)' : 'rotate(0deg)',
                cursor: 'pointer',
              }}
            />
          </Flex>
        </Flex>

        {expandable ? (
          <>
            {/* //! To map */}
            {items.map((item) => (
              <ExpandableCard item={item} selectedShipment={selectedShipment} />
            ))}
          </>
        ) : null}
        <GeneralInfo selectedShipment={selectedShipment} items={items} />
      </Flex>
    </>
  );
};

export default ExpandableCardSuccess;

const ImgContainer = styled.div`
  width: 118px;
  height: 118px;
  min-height: 118px;
  max-height: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1023px) {
  }
`;

const ImgStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdede;
  @media (min-width: 1024px) {
    display: none;
  }
`;
