import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
`;
export const Item20 = styled.div`
  min-width: 200px;
  width: 90px;
  max-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item15 = styled.div`
  min-width: 150px;
  width: 150px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
`;

export const Item10 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  width: 90px;
  max-width: 90px;
`;

export const Item5 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  width: 120px;
  max-width: 120px;
`;

export const GridContainer = styled.div`
  width: 100%;
  min-width: fit-content;
  display: flex;
  //grid-template-columns: auto auto auto auto auto auto auto;
  padding: 10px;
  align-items: center;
  justify-content: space-between;

  &:nth-child(odd) {
    background-color: #f4f4f4;
  }

  &:nth-child(even) {
    background-color: none;
  }

  ${(props) =>
    props.first
      ? 'border-bottom: 1px solid #b9cad0; margin-bottom: 1rem;'
      : 'null'}
`;

export const GridContainerRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  padding: 10px;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  color: #0d3564;
  margin: 0;
`;

export const ImagenTitle = styled.img`
  width: 48px;
`;

export const ImagenSubtitle = styled.img`
  width: 38px;
`;

export const ImagenArrow = styled.img`
  width: 24px;
`;

export const Subtitle = styled.p`
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  color: #0d3564;
  margin: 0;
  width: 100%;
  max-width: 100%;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TitleGrid = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  color: #55646a;
  text-align: center;
`;

export const TextGrid = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #14181a;
  text-align: center;
  word-break: break-word;
`;

export const ContainImg = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const Imagenes = styled.img`
  width: 24px;
  cursor: pointer;
`;

export const TitleGridContain = styled.div`
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTable = styled.div`
  width: 100%;
  overflow: auto;
  padding: 0 0 32px;

  ::-webkit-scrollbar {
    height: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    width: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #eff3f8;
    border-radius: 16px;
  }
`;
