/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  NavCont,
  NavRow,
  OptionsRow,
  Text,
  Button,
} from '../../styles/nav.styles';
import { url } from '../../api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ModalMenu from './ModalMenu/ModalMenu';
import { useHistory } from 'react-router-dom';
import { Flex } from '../../styles/common.styles';
const logoLarge = `${url}BallenaLogoLarge.svg`;
const menuIcon = `${url}MenuIcon.svg`;
const carrito = `${url}carrito.svg`;
import SellFormModal from '../MainPage/Modals/SellFormModal';
import FinishModal from '../MainPage/Modals/FinishModal';
import useShoppingCart from '../../../hooks/useShoppingCart';
import { useLocation } from 'react-router-dom';
const donwArrow = `${url}downArrow.svg`;
import userImg from '../CartComponent/assets/user.svg';
import styled from 'styled-components';
import useUser from '../../../hooks/useUser';

const Nav = ({}) => {
  const { user } = useUser();
  const [logged, setLogged] = useState(false);

  const location = useLocation();
  const actualLocation = location.pathname;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [unlog, setUnlog] = useState(false);

  const { itemsShoppingCart, clearLocalItems } = useShoppingCart();

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  // Agregar el evento 'resize' cuando el componente se monta
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    screenWidth >= 1024 ? setShow(false) : null;
  }, [screenWidth]);

  const [countItems, setCountItems] = useState(0);
  useEffect(() => {
    if (itemsShoppingCart) {
      const count = itemsShoppingCart?.reduce(
        (accumulator, currentValue) => accumulator + 1,
        0
      );
      setCountItems(count);
    }
  }, [itemsShoppingCart]);

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);

  function logOut() {
    clearLocalItems();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auxEmail');
    sessionStorage.removeItem('redux_user');
    sessionStorage.removeItem('redux_getPayment');
    sessionStorage.removeItem('idPart');
    sessionStorage.removeItem('refreshToken');
    history.push('/login');
    setLogged(false);
  }

  const manejarClicFuera = (e) => {
    const divChild = document.getElementById('child');

    if (divChild && !divChild.contains(e.target)) {
      // Clic fuera del div child, ocultar el div
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', manejarClicFuera);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', manejarClicFuera);
    };
  }, []);

  return (
    <>
      <SellFormModal
        setOpen={setOpenModal1}
        open={openModal1}
        finishModal={setOpenModal2}
      />
      <FinishModal open={openModal2} setOpen={setOpenModal2} />
      <NavRow id='child'>
        <NavCont mobile='padding:30px 24px; gap: 0;'>
          {screenWidth <= 1024 ? (
            <>
              <img
                src={menuIcon}
                onClick={(e) => {
                  setShow(!show);
                }}
                style={{ right: 0, position: 'sticky' }}
              />
            </>
          ) : null}
          <div
            onClick={() => history.push('/')}
            style={{ display: 'block', cursor: 'pointer' }}
          >
            <img
              src={logoLarge}
              style={{ width: screenWidth <= 1023 ? '91px' : null }}
            />
          </div>
          {screenWidth >= 1024 ? (
            <>
              <OptionsRow
                style={{ justifyContent: 'space-between', width: '100%' }}
              >
                <>
                  <Flex
                    width='fit-content'
                    gap='12px'
                    direction='row'
                    align='center'
                  >
                    {/* <img
                      src={menuIcon}
                      style={{ width: '12px', height: '12px' }}
                    /> */}
                    <Text
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push('/store')}
                    >
                      Catálogo
                    </Text>
                  </Flex>
                </>

                <>
                  <Flex
                    width='fit-content'
                    gap='12px'
                    direction='row'
                    align='center'
                  >
                    <Link
                      to='/quienes-somos'
                      style={{ textDecoration: 'none' }}
                    >
                      <Text>¿Quiénes somos?</Text>
                    </Link>
                    <Text
                      style={{ cursor: 'pointer' }}
                      onClick={() => setOpenModal1(true)}
                    >
                      ¿Quieres vender?
                    </Text>
                  </Flex>
                </>
                {/* //TODO: agregar to: /path a Link */}
                <Flex gap='32px' align='center'>
                  {actualLocation !== '/login' &&
                  actualLocation !== '/create-account' &&
                  !logged ? (
                    <Text
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push('/create-account')}
                    >
                      Crear cuenta
                    </Text>
                  ) : null}
                  <div style={{ position: 'relative' }}>
                    {!logged && (
                      <Button
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push(
                            actualLocation === '/login'
                              ? '/create-account'
                              : '/login'
                          )
                        }
                      >
                        {actualLocation === '/login'
                          ? 'Crear cuenta'
                          : 'Iniciar sesión'}
                      </Button>
                    )}
                    {logged && (
                      <Flex
                        gap='8px'
                        align='center'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setUnlog(!unlog)}
                      >
                        <img src={userImg} />
                        <Text>
                          ¡Hola {user.name} {user.surname || user.sur_name}!
                        </Text>
                        <img src={donwArrow} />
                        {unlog && (
                          <ComicDialog onClick={() => logOut()}>
                            Cerrar sesión
                          </ComicDialog>
                        )}
                      </Flex>
                    )}
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#0e79b4',
                        borderRadius: '50%',
                        height: '20px',
                        width: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '-11px',
                        right: '-14px',
                      }}
                    >
                      {' '}
                      <span
                        style={{
                          color: '#ffffff',
                          fontWeight: 'bold',
                        }}
                      >
                        {countItems}
                      </span>
                    </div>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={carrito}
                      onClick={() => history.push('/store/cart')}
                    />
                  </div>
                </Flex>
              </OptionsRow>
            </>
          ) : (
            <>
              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#0e79b4',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '-11px',
                    right: '-14px',
                  }}
                >
                  {' '}
                  <span
                    style={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                    }}
                  >
                    {countItems}
                  </span>
                </div>
                <img
                  style={{ cursor: 'pointer' }}
                  src={carrito}
                  onClick={() => history.push('/store/cart')}
                />
              </div>
            </>
          )}
        </NavCont>
        {show ? <ModalMenu openSell={setOpenModal1} /> : null}
      </NavRow>
    </>
  );
};

export default Nav;

const ComicDialog = styled.div`
  cursor: pointer;
  position: absolute;
  color: #0d3564;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  top: 44px;
  right: 0;
  width: 250px;
  z-index: 10;
  background-color: #eff3f8;
  border: 2px solid #eff3f8;
  border-radius: 8px;
  text-align: center;
  display: flex;
  padding: 11.5px 131px 11.5px 15px;
  filter: drop-shadow(0px 10px 12px rgba(13, 53, 100, 0.15));

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent #eff3f8 transparent;
    top: -20px;
    right: 10px;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    font-size: 1rem;
    margin: 10px 0;
  }

  button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;
