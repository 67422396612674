/* eslint-disable */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_STATUS_MARKET } from '../../../../graphql/Mutation';
import { Modal } from '@mui/material';
import SuccessModal from './SuccessModal';
import UncompleteModal from './UncompleteModal';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
} from '../../../styles/common.styles';
const ModalPublish = ({
  open,
  setOpen,
  row,
  reloadCurrentList,
  setLoading,
  setLoadingExcel,
}) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [OpenUncomplete, setOpenUncomplete] = useState(false);

  const [updateStatus] = useMutation(UPDATE_STATUS_MARKET, {
    fetchPolicy: 'no-cache',
    onCompleted({ updateStatusMarket }) {
      setOpen(false);
      setOpenSuccessModal(true);
      reloadCurrentList();
      setLoading(false);
      setLoadingExcel(false);
    },
  });

  const handleOn = () => {
    if (
      row.height === null ||
      row.height === undefined ||
      row.length == null ||
      row.length == undefined ||
      row.weight == null ||
      row.weight == undefined ||
      row.width == null ||
      row.width == undefined
    ) {
      setOpen(false);
      setOpenUncomplete(true);
    } else {
      setLoading(true);
      setLoadingExcel(true);
      updateStatus({
        variables: {
          id: row.idPart,
          status: 1,
        },
      });
      // setOpenSuccessModal(true);
      // reloadCurrentList();
    }
  };
  return (
    <>
      <UncompleteModal open={OpenUncomplete} setOpen={setOpenUncomplete} />
      <SuccessModal
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        reloadCurrentList={reloadCurrentList}
        setLoading={setLoading}
      />
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Text size='32px' weight='800' color='#0D3564'>
              Publicar producto
            </Text>
            <Text family='Inter' size='16px' weight='400' color='#14181A'>
              ¿Estás seguro que deseas publicar el producto?{' '}
            </Text>
            <Flex align='center' gap='24px' justify='center'>
              <Button width='194px' onClick={() => handleOn()}>
                Publicar
              </Button>
              <Button secondary width='194px' onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default ModalPublish;
