/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_SEGMENT } from '../../../../graphql/Query';
//styles
import {
  MainContainer,
  ArrowDown,
  Divider,
  GridContainer,
  GridText,
  GridTitle,
  ImgEdit,
  ImgGrid,
  ImgSubtitle,
  Subtitle,
  SubtitleContainer,
  SubtitleContainerText,
  ContainerBottom,
  InputContainer,
  InputDescuento,
  LabelInput,
  ButtonDescuento,
} from '../UsuariosMkt.styles';
import { Checkbox, styled } from '@mui/material';
import MassiveDiscountModal from '../../DiscountModals/MassiveDiscountModal';
import CustomDiscountModal from '../../DiscountModals/CustomDiscountModal';
import MassiveDiscountConfirmModal from '../../DiscountModals/MassiveDiscountConfirmModal';
import { url } from '../../../api';
const UserDiscount = `${url}UserDiscount.svg`;
const edit = `${url}Editar.svg`;
const flecha = `${url}downArrow.svg`;

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  // Estilos para el borde en unchecked
  '&.MuiButtonBase-root': {
    color: '#fff',
    // border: '#0E79B4',
    width: '18px',
    height: '18px',
    border: '2px solid #0E79B4',
    borderRadius: '4px',
  },
  // Estilos para el fondo en checked
  '&.Mui-checked': {
    '&.MuiCheckbox-root': {
      backgroundColor: '#41B770',
      border: '2px solid #0E79B4',
      borderRadius: '4px',
      width: '18px',
      height: '18px',
    },
    // Estilos para el color de la palomita en checked
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
}));

const ExpandableRow = ({
  Title,
  users,
  isU0,
  isU1,
  isU2,
  isU3,
  getAllUsers,
  index,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [openM, setOpenM] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [seleccionado, setSeleccionado] = useState('');
  const [seleccionadoEmail, setSeleccionadoEmail] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [discount, setDiscount] = useState([]);
  const [valueDiscount, setValueDiscount] = useState(0);
  const [newDiscount, setNewDiscount] = useState(0);

  const [getSegments] = useLazyQuery(GET_USER_SEGMENT, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setDiscount(data.getUserSegment);
    },
    onError(err) {},
  });

  const handleValueChange = (e) => {
    const oldValue = e.target.value;
    let newValue = parseInt(oldValue.replace(/[^\d]/g, ''), 10);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    newValue = Math.min(Math.max(newValue, 0), 100);
    const cursorPosition = e.target.selectionStart;
    e.target.value = `${newValue}%`;
    const newCursorPosition = Math.min(
      cursorPosition,
      newValue.toString().length
    );
    setNewDiscount(newValue);
    e.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const handleClick = () => {
    setOpenC(true);
  };

  const handleChangeTable = () => {
    setOpen(true);
  };

  const handleEdit = (email, user) => {
    console.log(user);
    setSeleccionadoEmail(email);
    setSeleccionado(user);
    setOpenM(true);
  };

  const handleArrowDown = () => {
    setExpanded(!expanded);
    let id = '';
    if (isU0) {
      id = 'U0';
    } else if (isU1 || isU2 || isU3) {
      id = isU1 ? 'U1' : isU2 ? 'U2' : 'U3';
      const obj = discount.find((element) => {
        return element.description === id;
      });
      setValueDiscount(obj.discount);
    }
    if (id) {
      sessionStorage.setItem('id', id);
    }
  };

  const handleCheck = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(selectedEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  useEffect(() => {
    getSegments();
  }, []);

  /*   useEffect(() => {
    sessionStorage.setItem('selectedEmails', JSON.stringify(selectedEmails));
  }, [selectedEmails]); */

  useEffect(() => {
    const emails = users.map((item) => item.email);
    if (selectAll == true) {
      setSelectedEmails(emails);
    }
    if (selectAll === false) {
      setSelectedEmails([]);
    }
  }, [selectAll]);

  useEffect(() => {
    console.log(selectedEmails);
    if (selectedEmails.length > 0) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedEmails]);

  useEffect(() => {
    console.log(users);
    return () => {
      // limpieza del efecto
    };
  }, [users]);

  return (
    <>
      <CustomDiscountModal
        open={openM}
        setOpen={setOpenM}
        users={users}
        selectedEmail={seleccionadoEmail}
        getAllUsers={getAllUsers}
        index={index}
        valueDiscount={valueDiscount}
        seleccionado={seleccionadoEmail}
        infoUser={seleccionado}
      />
      <MainContainer>
        <SubtitleContainer onClick={handleArrowDown}>
          <SubtitleContainerText>
            <ImgSubtitle src={UserDiscount} alt='' />
            <Subtitle>{Title} </Subtitle>
          </SubtitleContainerText>
          <ArrowDown alt='' src={flecha} style={{ cursor: 'pointer' }} />
        </SubtitleContainer>
        {expanded && (
          <>
            <GridContainer>
              {/* <ImgGrid
                src={checkCircle}
                alt=''
                style={{ width: '24px', height: 'auto' }}
              /> */}
              <div
                style={{
                  width: '100%',
                  padding: '1rem',
                  borderBottom: '3px solid #c5cad1',
                  height: '100%',
                  margin: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CustomCheckbox
                  onClick={() => setSelectAll(!selectAll)}
                  checked={selectAll}
                />
              </div>
              <GridTitle>Nombre de usuario</GridTitle>
              <GridTitle>Correo electrónico</GridTitle>
              <GridTitle>Número de contacto</GridTitle>
              <GridTitle>Dirección</GridTitle>
              <GridTitle>Tipo de usuario</GridTitle>
              <p
                style={{
                  width: '100%',
                  padding: '1rem',
                  borderBottom: '3px solid #c5cad1',
                  height: '100%',
                  margin: '0',
                }}
              ></p>
              {users.length > 0 ? (
                <>
                  {users.map((user) => (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '1rem',
                          borderBottom: '2px solid #EFF3F8',
                        }}
                      >
                        {' '}
                        <CustomCheckbox
                          onClick={() => handleCheck(user.email)}
                          checked={selectedEmails.includes(user.email)}
                        />
                      </div>
                      <GridText>
                        {[user.name,
                        user.secondName,
                        user.surName,
                        // user.surName2
                        ].filter(Boolean).join(' ')}
                      </GridText>
                      <GridText>{user.email}</GridText>
                      <GridText>{user.phone}</GridText>
                      <GridText>{user.adress}</GridText>
                      <GridText>{user.typeUser}</GridText>
                      <ImgEdit
                        onClick={() => handleEdit(user.email, user)}
                        src={edit}
                        alt=''
                        style={{
                          width: '54px',
                          height: '100%',
                          cursor: 'pointer',
                          padding: '1rem',
                          borderBottom: '2px solid #EFF3F8',
                        }}
                      />
                    </>
                  ))}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    gridColumnStart: '4',
                    gridColumnEnd: '5',
                  }}
                >
                  <GridTitle>Sin información</GridTitle>
                </div>
              )}
            </GridContainer>
            <ContainerBottom>
              <InputContainer>
                <LabelInput>Descuento actual</LabelInput>
                <InputDescuento
                  placeholder='0%'
                  disabled
                  // value={valueDiscount ? `${valueDiscount}%` : 0}
                  value={newDiscount ? `${newDiscount}%` : `${valueDiscount}%`}
                />
              </InputContainer>
              {!isU0 && (
                <InputContainer>
                  <LabelInput>Nuevo descuento</LabelInput>
                  <InputDescuento
                    placeholder='0%'
                    onChange={(e) => handleValueChange(e)}
                    disabled={isU0}
                  />
                </InputContainer>
              )}
              {isU0 && (
                <ButtonDescuento
                  onClick={handleChangeTable}
                  disabled={!isSelected}
                >
                  Gestionar descuentos
                </ButtonDescuento>
              )}
              {!isU0 && (
                <>
                  <ButtonDescuento onClick={handleClick}>
                    Aplicar descuentos
                  </ButtonDescuento>
                  <ButtonDescuento onClick={handleChangeTable} secondary>
                    Administrar usuarios
                  </ButtonDescuento>
                </>
              )}
              <MassiveDiscountModal
                open={open}
                setOpen={setOpen}
                getAllUsers={getAllUsers}
                onClose={setSelectedEmails}
                index={index}
                selectedEmails={selectedEmails}
              />
              <MassiveDiscountConfirmModal
                open={openC}
                setOpen={setOpenC}
                getSegments={getSegments}
                setSelectAll={setSelectAll}
                onClose={setSelectedEmails}
                index={index}
                newDiscount={newDiscount}
              />
            </ContainerBottom>
          </>
        )}
      </MainContainer>
    </>
  );
};

export default ExpandableRow;
