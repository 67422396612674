import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Text,
  FlexSticky,
  Tooltip,
  TooltipExtra,
} from '../../../styles/common.styles';
import { ItemCard } from '../cart.styles';
import ModalDelete from '../ModalDelete';
import DesplegablePq from './PaqueteriaElegir/DesplegablePq';
import PaqueteriaDrop from './PaqueteriaElegir/PaqueteriaDrop/PaqueteriaDrop';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../helpers/shopCart';
import info from '../assets/info.svg';
import { Backdrop } from '@mui/material';
import ScrollToTop from '../../../../hooks/topHook';
import styled from 'styled-components';

const CurrentGroup = ({ handleBuySingle, finalPrice, group, index, info }) => {
  const [openCard, setOpenCard] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [countItems, setCountItems] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      // limpieza del efecto
    };
  }, []);
  useEffect(() => {
    const sub = group.reduce(
      (accumulator, currentValue) =>
        accumulator + stringToNumber(currentValue.price) * currentValue.qty,
      0
    );
    const count = group.reduce(
      (accumulator, currentValue) => accumulator + currentValue.qty,
      0
    );
    setSubTotal(sub);
    setCountItems(count);
  }, [group]);
  return (
    <Flex
      direction='column'
      width='100%'
      padding='0 32px'
      bg='#EFF3F8'
      gap='4px'
    >
      <ModalDelete
        open={openModal}
        setOpen={setOpenModal}
        box
        id={Object.keys(info)[index]}
      />
      <DesplegablePq
        openCard={openCard}
        setOpenCard={setOpenCard}
        index={index}
      />
      {openCard &&
        group?.map((item) => (
          <ItemCard
            item={item}
            handleBuy={handleBuySingle}
            finalPrice={finalPrice}
            setFinalPice={setSubTotal}
            setCountItems={setCountItems}
            countItems={countItems}
          />
        ))}
      {openCard && (
        <>
          <Flex
            width='100%'
            bg='#EFF3F8'
            padding='32px'
            direction='row'
            gap='16px'
            mobile='flex-direction: column; padding: 32px 0;'
          >
            <Flex
              width='100%'
              direction='column'
              gap='16px'
              align='start'
              mobile='align-items: end;'
            >
              <Flex width='100%' gap='12px' align='center' justify='flex-end'>
                <Text weight='700' color='#022741' family='Inter'>
                  Subtotal ({countItems} productos):
                </Text>
                <Text
                  color='#55646A'
                  weight='700'
                  width='200px'
                  mobile='text-align:right;'
                >
                  ${formatNumberWithTwoDecimals(subTotal)} MXN
                </Text>
              </Flex>
              <Button
                secondary
                maxwidth='218px'
                width='100%'
                onClick={() => setOpenModal(true)}
                style={{ background: 'none' }}
              >
                Eliminar todos
              </Button>
            </Flex>
            <Divider />
            <Flex width='100%' direction='column' gap='16px' align='flex-end'>
              <Flex
                gap='12px'
                direction='row'
                justify='space-between'
                width='100%'
              >
                <Text
                  width='100%'
                  textAlign='right'
                  size='14px'
                  color='#55646A'
                  family='Inter'
                >
                  Envío:
                </Text>
                <Text
                  width='200px'
                  minWidth='200px'
                  color='#8299A3'
                  size='16px'
                  family='Inter'
                  textAlign='right'
                >
                  No has seleccionado
                </Text>
              </Flex>
              <Flex
                gap='12px'
                direction='row'
                justify='space-between'
                width='100%'
              >
                <Text
                  width='100%'
                  textAlign='right'
                  family='Inter'
                  size='16px'
                  weight='700'
                  color='#022741'
                >
                  Subtotal:
                </Text>
                <Text
                  width='200px'
                  minWidth='200px'
                  family='Inter'
                  size='18px'
                  weight='700'
                  color='#0E79B4'
                  textAlign='right'
                >
                  ${formatNumberWithTwoDecimals(subTotal)} MXN
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      {!openCard && (
        <>
          <PaqueteriaDrop
            nopad
            costoEnvio={'$0.00 MXN'}
            item={group}
            handleBuy={handleBuySingle}
            countItems={countItems}
            subTotal={subTotal}
          />
        </>
      )}
    </Flex>
  );
};
const TuCarrito = ({ setStepSelected, items }) => {
  const [claves, setClaves] = useState([]);
  const [groups, setGroups] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    let nuevoObjeto = {};
    items.forEach((x) => {
      if (!nuevoObjeto.hasOwnProperty(x.id_uac)) {
        nuevoObjeto[x.id_uac] = {
          data: [],
        };
      }
      nuevoObjeto[x.id_uac].data.push(x);
    });
    let tempclaves = Object.keys(nuevoObjeto);
    setGroups(nuevoObjeto);
    setClaves(tempclaves);
  }, [items]);

  const [subTotal, setSubTotal] = useState(0);
  const [countItems, setCountItems] = useState(0);

  const [infoDisplay, setInfoDisplay] = useState(false);

  const handleBuyAll = () => {
    setStepSelected(1);
  };

  const handleBuySingle = () => {
    setStepSelected(1);
  };

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const sub = items.reduce(
      (accumulator, currentValue) =>
        accumulator + stringToNumber(currentValue.price) * currentValue.qty,
      0
    );
    const count = items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.qty,
      0
    );
    setSubTotal(sub);
    setCountItems(count);
  }, [items]);

  const manejarClicFuera = (e) => {
    if (screenWidth >= 1024) {
      const divChild = document.getElementById('tooltip');

      if (divChild && !divChild.contains(e.target)) {
        // Clic fuera del div child, ocultar el div
        setInfoDisplay(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', manejarClicFuera);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', manejarClicFuera);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <ModalDelete open={openModal} setOpen={setOpenModal} all />
      <Text
        color='#004C97'
        width='100%'
        textAlign='center'
        size='40px'
        weight='800'
        mobile='font-size: 28px;'
      >
        Tu carrito
      </Text>

      <Flex
        width='100%'
        direction='row'
        gap='40px'
        mobile='flex-direction: column;'
        extras='border-top: 0.74px solid rgba(0, 0, 0, 0.35);'
        padding='64px 0 0'
      >
        <Flex width='100%' direction='column' gap='40px'>
          <Text
            color='#004C97'
            width='100%'
            textAlign='left'
            size='24px'
            weight='700'
          >
            Productos
            <img
              alt=''
              src={info}
              style={{
                marginLeft: '8px',
              }}
              onMouseEnter={() => {
                setInfoDisplay(true);
              }}
              onTouchEnd={() => setInfoDisplay(true)}
            />
            {infoDisplay ? (
              <>
                <Backdrop open sx={{ zIndex: 2000 }} />
                <Tooltip
                  style={{
                    zIndex: '2001',
                    width: '100%',
                    maxWidth: '400px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  id='tooltip'
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                      fontSize: '16px',
                      color: '#32B7D7',
                      cursor: 'pointer',
                    }}
                    onClick={() => setInfoDisplay(false)}
                  >
                    ✕
                  </div>
                  <Flex width='100%' direction='row' gap='8px'>
                    <img alt='' src={info} />
                    <Text size='18px' weight='600' color='#32B7D7'>
                      Envíos por paquetes
                    </Text>
                  </Flex>
                  <Text size='12px' color='#0D3564' family='Inter'>
                    Los productos que has seleccionado se organizan en distintos
                    paquetes, basados en la ubicación de cada proveedor.
                    <br />
                    <br />A continuación, tendrás la opción de elegir el
                    servicio de paquetería que más te convenga, tomando en
                    cuenta tus preferencias y tiempos de entrega.
                  </Text>
                  <TooltipExtra />
                </Tooltip>
              </>
            ) : null}
          </Text>
          {claves?.map((currentItems, index) => (
            <>
              <CurrentGroup
                handleBuySingle={handleBuySingle}
                finalPrice={subTotal}
                setSubTotal={setSubTotal}
                setCountItems={setCountItems}
                countItems={countItems}
                group={groups[currentItems].data}
                subTotal={subTotal}
                index={index}
                info={groups}
              />
            </>
          ))}
        </Flex>
        <Flex
          width='100%'
          direction='column'
          gap='40px'
          maxWidth='370px'
          minWidth='270px'
          mobile='max-width: 100%;'
        >
          <Text
            color='#004C97'
            width='100%'
            textAlign='center'
            size='24px'
            weight='700'
          >
            Resumen de compra
          </Text>
          <FlexSticky
            padding='32px'
            direction='column'
            gap='48px'
            extras='box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);'
            width='100%'
            mobile='padding: 32px;'
          >
            <Text textAlign='left'>
              Subtotal ({countItems} productos):{' '}
              <span style={{ color: '#8299A3' }}>
                ${formatNumberWithTwoDecimals(subTotal)} MXN
              </span>
            </Text>
            {/* <Text textAlign='left' style={{ marginTop: '0' }}>
              Costo de envío:
              <span style={{ color: '#8299A3' }}> ${`0.00`} MXN </span>
            </Text> */}
            <Text
              textAlign='left'
              family='Roboto'
              size='24px'
              weight='700'
              color='#022741'
            >
              Total:&nbsp;&nbsp;&nbsp;
              <span style={{ fontWeight: '500px' }}>
                ${formatNumberWithTwoDecimals(subTotal)} MXN
              </span>
            </Text>
            <Flex direction='column' gap='16px' align='center' width='100%'>
              <Button
                disabled={countItems === 0}
                width='100%'
                onClick={() => handleBuyAll()}
              >
                Comprar todos
              </Button>
              <Button width='100%' secondary onClick={() => setOpenModal(true)}>
                Eliminar todos
              </Button>
            </Flex>
          </FlexSticky>
        </Flex>
      </Flex>
    </>
  );
};

export default TuCarrito;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdede;
  @media (min-width: 1024px) {
    display: none;
  }
`;
