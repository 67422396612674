import styled from 'styled-components';
import Send from './Assets/Send.svg';
import { useEffect } from 'react';

export const Styledicon = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(14, 121, 180, 1);
  right: 2rem;
  z-index: 1000;
  bottom: 2rem;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06),
    0px 2px 32px 0px rgba(0, 0, 0, 0.16);
  img {
  }
`;

export const StyledContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  max-width: 400px;
  max-height: 560px;
  width: 80%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  box-shadow: 0px 6px 8px 0px rgba(241, 228, 233, 0.15),
    0px 0px 62.6px 18px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  bottom: 8rem;
  right: 2rem;
`;

export const StyledHead = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: row;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: #0e79b4;
  height: 104px;
  position: relative;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  gap: 16px;
`;

export const StyledCont = styled.div`
  display: flex;
  width: 100%;
  height: 358px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const BottomStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  height: 98px;
  border-top: 1px solid #b9cad0;
  padding: 0 16px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  height: 50px;
  padding: 0 20px;
  gap: 8px;
  align-items: center;
`;
const UnstyledInputContainer = styled.input`
  width: 100%;
  border: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  outline: none;
`;

const UserRowResponse = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const BotRowResponse = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const StyledUserResponse = styled.div`
  display: flex;
  padding: 13.5px 12px 13.5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 15px 15px 0px 15px;
  background: #0e79b4;
  width: fit-content;
  max-width: 70%;
  word-break: break-word;
  min-width: 100px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  min-height: 52.14px;
`;

const StyledBotResponse = styled.div`
  display: flex;
  padding: 13.5px 12px 13.5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 16px 16px 16px;
  background: #eff3f8;
  width: fit-content;
  max-width: 70%;
  word-break: break-word;
  min-width: 100px;
  color: #55646a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  min-height: 52.14px;
  flex-direction: column;
`;

const StyledLoading = styled.div`
  width: 30px;
  margin: 0 auto;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #55646a73 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
  @keyframes l1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
`;

export const StyledInput = ({
  handleSendMessage,
  handleChange,
  setMessage,
  message,
}) => {
  const handleClickSendMessage = (event) => {
    handleSendMessage(event);
    setMessage('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Lógica que deseas ejecutar al presionar Enter
      message !== '' && handleClickSendMessage(event);
    }
  };

  return (
    <>
      <StyledInputContainer>
        <UnstyledInputContainer
          onChange={(event) => handleChange(event)}
          value={message}
          onKeyDown={handleKeyDown}
        />
        <img
          alt=''
          src={Send}
          style={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={(event) => {
            message !== '' && handleClickSendMessage(event);
          }}
        />
      </StyledInputContainer>
    </>
  );
};

export const UserResponse = ({ response }) => {
  return (
    <>
      <UserRowResponse>
        <StyledUserResponse>{response}</StyledUserResponse>
      </UserRowResponse>
    </>
  );
};

export const BotResponse = ({ response, isWriting }) => {
  const detectarEnlaces = (texto) => {
    // Expresión regular mejorada para detectar enlaces
    const regexEnlace = /(https?:\/\/[^\s]+(?:\.[^\s]+)*(?=\s|$))/g;

    // Dividir el texto en partes que son enlaces y partes que no lo son
    const partes = texto.split(regexEnlace);

    // Mapear las partes y envolver los enlaces en elementos <a>
    return partes.map((parte, index) => {
      if (regexEnlace.test(parte)) {
        function quitarPuntoFinal(cadena) {
          // Verificar si el último carácter es un punto
          if (cadena.slice(-1) === '.') {
            // Eliminar el último carácter si es un punto
            return cadena.slice(0, -1);
          }
          // Devolver la cadena sin cambios si el último carácter no es un punto
          return cadena;
        }

        const resultado = quitarPuntoFinal(parte);
        return (
          <a
            key={index}
            href={resultado}
            target='_blank'
            rel='noopener noreferrer'
          >
            {resultado}
          </a>
        );
      } else {
        return parte;
      }
    });
  };

  return (
    <>
      <BotRowResponse>
        <StyledBotResponse>
          {isWriting ? <StyledLoading /> : detectarEnlaces(response)}
        </StyledBotResponse>
      </BotRowResponse>
    </>
  );
};
