/* eslint-disable */
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_LEADER_SELL } from '../../../../graphql/Mutation';
import styled from 'styled-components';
import {
  Flex,
  StyledInput,
  StyledSelect,
  Text,
  Button,
} from '../../../styles/common.styles';
import { url } from '../../../api';
const iconUserr = `${url}personalDataIcon.svg`;
const iconContact = `${url}contactDataIcon.svg`;
import PhoneInput from 'react-phone-input-2';

const SellFormModal = ({ open, setOpen, finishModal }) => {
  const [tryContinue, setTryContinue] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [validTel, setValidTel] = useState(false);
  const [validPostalCode, setValidPostalCode] = useState(false);
  const [validForm, setValidForm] = useState({
    firstName: '',
    secondName: '',
    fatherLastName: '',
    motherLastName: '',
    refactions: '',
    brands: '',
    comment: '',
    companyName: '',
    phone: '',
    email: '',
    postalCode: '',
  });

  const resetValidForm = () => {
    setValidForm({
      firstName: '',
      secondName: '',
      fatherLastName: '',
      motherLastName: '',
      refactions: '',
      brands: '',
      comment: '',
      companyName: '',
      phone: '',
      email: '',
      postalCode: '',
    });
    setValidEmail(false);
    setValidTel(false);
    setValidPostalCode(false);
  };

  const [registerSeller] = useMutation(ADD_LEADER_SELL, {
    onCompleted: (data) => {
      // console.log(data);
    },
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  useEffect(() => {
    if (open) {
      resetValidForm();
    }
  }, [open]);

  let type = true;
  if (validForm.refactions == 'Originales') {
    type = true;
  } else if (validForm.refactions == 'Genéricas') {
    type = false;
  }
  const handleSend = () => {
    if (
      !validEmail ||
      !validPostalCode ||
      !validTel ||
      validForm.firstName == '' ||
      validForm.fatherLastName == '' ||
      validForm.motherLastName == '' ||
      validForm.refactions == '' ||
      validForm.brands == '' ||
      validForm.companyName == '' ||
      validForm.phone == '' ||
      validForm.email == '' ||
      validForm.postalCode == ''
    ) {
      console.log('funciona');
      setTryContinue(true);
    } else {
      registerSeller({
        variables: {
          name: validForm.firstName,
          surname: validForm.secondName,
          lastname: validForm.fatherLastName,
          second_lastname: validForm.motherLastName,
          email: validForm.email,
          phone: validForm.phone,
          company: validForm.companyName,
          cp: validForm.postalCode,
          original: type,
          number_brands: validForm.brands,
          type_lead: 1,
          comment: validForm.comment,
        },
      });
      setOpen(false);
      finishModal(true);
    }
  };

  const handleValidation = (type, e) => {
    if (type === 'tel') {
      const value = e;
      const regex = /^[0-9]{12}$/;
      if (regex.test(value) === true) {
        setValidTel(true);
      } else {
        setValidTel(false);
      }
      setValidForm({ ...validForm, phone: value });
    }
    if (type === 'email') {
      const value = e.target.value;
      const regex = /^[\w+\-.]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      const newValue = value.replace(/[^\w+@.-]+/g, '');
      e.target.value = newValue;
      if (regex.test(newValue) === true) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
      setValidForm({ ...validForm, email: newValue });
    }
    if (type === 'postalCode') {
      const value = e.target.value;
      const newValue = value.replace(/[^\d]/g, '').slice(0, 5);
      e.target.value = newValue;
      const regex = /^[0-9]{5}$/;
      if (regex.test(newValue) === true) {
        setValidPostalCode(true);
      } else {
        setValidPostalCode(false);
      }
      setValidForm({ ...validForm, postalCode: newValue });
    }
  };
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <div
              style={{
                position: 'absolute',
                right: '12px',
                top: '12px',
                fontSize: '1.5rem',
                color: '#888',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpen(false);
                setTryContinue(false);
              }}
            >
              ✕
            </div>
            <Title>¿Quieres vender?</Title>
            <Text>
              Comparte estos datos y un representante de Ballena se pondrá en
              contacto contigo. Descubre si puedes unirte a nuestra plataforma y
              ser parte de la experiencia.
            </Text>

            <Flex
              //Parcial Form Container
              width='100%'
              gap='16px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='8px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={iconUserr} />
                <Text size='24px' weight='700' color='#0D3564'>
                  Datos personales
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='16px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Primer nombre
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu primer nombre'
                    onChange={(e) =>
                      setValidForm({ ...validForm, firstName: e.target.value })
                    }
                    error={tryContinue && validForm.firstName == ''}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Segundo nombre
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu segundo nombre'
                    onChange={(e) =>
                      setValidForm({ ...validForm, secondName: e.target.value })
                    }
                  />
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='16px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Apellido paterno
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu apellido paterno'
                    onChange={(e) =>
                      setValidForm({
                        ...validForm,
                        fatherLastName: e.target.value,
                      })
                    }
                    error={tryContinue && validForm.fatherLastName == ''}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Apellido materno
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu apellido materno'
                    onChange={(e) =>
                      setValidForm({
                        ...validForm,
                        motherLastName: e.target.value,
                      })
                    }
                    error={tryContinue && validForm.motherLastName == ''}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex
              //Parcial Form Container
              width='100%'
              gap='16px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='8px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={iconContact} />
                <Text size='24px' weight='700' color='#0D3564'>
                  Datos de contacto
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='16px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Correo electrónico
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu correo electrónico'
                    onChange={(e) => handleValidation('email', e)}
                    error={tryContinue && validForm.email == ''}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número celular
                  </Text>
                  <PhoneInput
                    country='mx'
                    containerStyle={{ width: '100%' }}
                    inputStyle={{
                      width: '100%',
                      height: '48px',
                      borderRadius: '8px',
                      border:
                        validForm.phone == '' && tryContinue
                          ? '1px solid #D22121'
                          : '1px solid #b9cad0',
                      boxShadow: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    onChange={(e) => handleValidation('tel', e)}
                    isValid={
                      validForm.phone == '' && tryContinue ? false : true
                    }
                  />
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='16px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Nombre de empresa{' '}
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa el nombre de tu empresa'
                    onChange={(e) =>
                      setValidForm({
                        ...validForm,
                        companyName: e.target.value,
                      })
                    }
                    error={tryContinue && validForm.companyName == ''}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Código postal
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu código postal'
                    onChange={(e) => handleValidation('postalCode', e)}
                    error={tryContinue && validForm.postalCode == ''}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              //Input container
              direction='column'
              gap='4px'
              width='100%'
            >
              <Text family='Inter' size='14px' weight='700'>
                ¿Las refacciones de tu empresa son originales o genéricas?
              </Text>
              <StyledSelect
                onChange={(e) =>
                  setValidForm({ ...validForm, refactions: e.target.value })
                }
                style={{
                  color: validForm.refactions === '' ? '#8299A3' : null,
                }}
                error={tryContinue && validForm.refactions == ''}
              >
                <option value='' disabled selected hidden>
                  Selecciona una opción
                </option>
                <option value='Originales'>Originales</option>
                <option value='Genéricas'>Genéricas</option>
              </StyledSelect>
            </Flex>
            <Flex
              //Input container
              direction='column'
              gap='4px'
              width='100%'
            >
              <Text family='Inter' size='14px' weight='700'>
                ¿Alrededor de cuantas marcas de refacciones maneja tu empresa?
              </Text>
              <StyledSelect
                onChange={(e) =>
                  setValidForm({ ...validForm, brands: e.target.value })
                }
                style={{
                  color: validForm.brands === '' ? '#8299A3' : null,
                }}
                error={tryContinue && validForm.brands == ''}
              >
                <option value='' disabled selected hidden>
                  Selecciona una opción
                </option>
                <option value='1'>1</option>
                <option value='2-3'>1 - 3</option>
                <option value='4-5'>1 - 5</option>
                <option value='Más de 5'>Más de 5</option>
              </StyledSelect>
            </Flex>
            <Flex
              //Input container
              direction='column'
              gap='4px'
              width='100%'
            >
              <Text family='Inter' size='14px' weight='700'>
                Comentarios
              </Text>
              <StyledTextArea
                onChange={(e) =>
                  setValidForm({ ...validForm, comment: e.target.value })
                }
              />
            </Flex>
            <Flex
              direction='row-reverse'
              wisth='100%'
              justify='center'
              align='center'
              gap='16px'
              mobile='flex-direction: column;'
              width='100%'
            >
              <Button
                width='100%'
                onClick={() => handleSend()}
                mobile='width: 100%;'
              >
                Enviar
              </Button>
              <Button
                width='100%'
                secondary
                onClick={() => {
                  setOpen(false);
                  setTryContinue(false);
                }}
                mobile='width: 100%;'
              >
                Cancelar
              </Button>
            </Flex>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default SellFormModal;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 48px 40px 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 32px;
  width: 100%;
  max-width: 630px;
  max-height: 100%;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: thin; /* Anchura de la barra de desplazamiento */
  scrollbar-color: #888 #f0f0f0; /* Color de la barra y el fondo */

  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente */
  }
  @media (max-width: 1023px) {
    padding: 48px 40px 32px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
`;

const WarningSign = styled.img`
  width: 64px;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const ButtonEliminar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  border-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
`;

const CancelarButton = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
  cursor: pointer;
  background-color: #ffffff;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 0 8px;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #fff;
  resize: none; /* Evitar redimensionamiento */
  font-size: 16px;
  outline: none;
  &:focus {
    border: 1px solid #0d3564;
  }
`;
