/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Text,
  StyledInput,
  StyledSelect,
} from '../../../styles/common.styles';
import { url } from '../../../api';
import PhoneInput from 'react-phone-input-2';
import useUser from '../../../../hooks/useUser';
import { useLazyQuery } from '@apollo/client';
import { GET_COLONY_BY_CP } from '../../../../graphql/Query';
import LogInForm from '../../common/LogInV2/LogInForm.jsx/LogInForm';
import ScrollToTop from '../../../../hooks/topHook';
const iconContact = `${url}contactDataIcon.svg`;
const ubicacion = `${url}ubicacion.svg`;

const DatosEnvio = ({ saveProgress, user }) => {
  const [validEmail, setValidEmail] = useState(false);
  const [validTel, setValidTel] = useState(false);
  const [validPostalCode, setValidPostalCode] = useState(false);
  const [send, setSend] = useState(false);
  const [validForm, setValidForm] = useState({
    phone: '',
    email: '',
    region: '',
    city: '',
    colonia: '',
    street: '',
    extNumber: '',
    intNumber: '',
    comments: '',
    postalCode: '',
  });

  useEffect(() => {
    console.log('user-------------');
    console.log({ user });
    if (!send) {
      setValidForm({
        phone: user?.phone,
        email: user?.email,
        region: user[`cat_adress_usermktps.estado`],
        city: user[`cat_adress_usermktps.city`],
        colonia: user[`cat_adress_usermktps.colonia`],
        street: user[`cat_adress_usermktps.calle`],
        extNumber: user[`cat_adress_usermktps.num_exterior`],
        intNumber: user[`cat_adress_usermktps.num_interior`],
        comments: user[`cat_adress_usermktps.referencia`],
        postalCode: user[`cat_adress_usermktps.zip`],
      });
      if (user?.phone) {
        setValidTel(true);
      }
      if (user?.email) {
        setValidEmail(true);
      }
      if (user[`cat_adress_usermktps.zip`]) {
        setValidPostalCode(true);
      }
    }
  }, [user]);
  const [colonies, setColonies] = useState([]);
  const [getColony] = useLazyQuery(GET_COLONY_BY_CP, {
    fetchPolicy: 'no-cache',
    onCompleted({ getColony }) {
      const parsedInfo = JSON.parse(getColony.response);
      setColonies(parsedInfo);
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    if (user?.phone) {
      setValidTel(true);
    }
    if (user?.email) {
      setValidEmail(true);
    }
    if (user[`cat_adress_usermktps.zip`]) {
      setValidPostalCode(true);
    }
  }, []);

  const handleValidation = (type, e) => {
    if (type === 'tel') {
      const value = e;
      const regex = /^[0-9]{12}$/;
      if (regex.test(value) === true) {
        setValidTel(true);
      } else {
        setValidTel(false);
      }
      setValidForm({ ...validForm, phone: value });
    }
    if (type === 'email') {
      const value = e.target.value;
      const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const newValue = value.replace(/[^\w@.-]+/g, '');
      e.target.value = newValue;
      if (regex.test(newValue) === true) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
      setValidForm({ ...validForm, email: newValue });
    }
    if (type === 'postalCode') {
      const value = e.target.value;
      const newValue = value.replace(/[^\d]/g, '').slice(0, 5);
      e.target.value = newValue;
      const regex = /^[0-9]{5}$/;
      if (regex.test(newValue) === true) {
        setValidPostalCode(true);
        getColony({
          variables: {
            cp: newValue,
          },
        });
      } else {
        setValidPostalCode(false);
      }
      setValidForm({
        ...validForm,
        postalCode: newValue,
        region: '',
        city: '',
      });
    }
  };

  const onChangeColony = (colonia) => {
    const tempInfo = colonies.filter(
      (item) => item.settlement_name === colonia
    );
    setValidForm({
      ...validForm,
      region: tempInfo[0]?.state,
      city: tempInfo[0]?.city,
      colonia,
    });
  };

  return (
    <>
      <ScrollToTop />
      <Text
        color='#004C97'
        width='100%'
        textAlign='center'
        size='40px'
        weight='800'
        mobile='font-size: 28px;'
      >
        Datos de envío
      </Text>
      {user?.['cat_adress_usermktps.IdCatAdressUserMktp'] ? (
        <>
          <Flex
            width='100%'
            direction='column'
            gap='40px'
            extras='border-top: 0.74px solid rgba(0, 0, 0, 0.35);'
            padding='64px 0 40px'
          >
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='8px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={iconContact} />
                <Text size='28px' weight='800' color='#0D3564'>
                  Datos de contacto
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Correo electrónico
                  </Text>
                  <StyledInput
                    type='text'
                    value={validForm.email}
                    placeholder='Ingresa tu correo electrónico'
                    onChange={(e) => handleValidation('email', e)}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número celular
                  </Text>
                  <PhoneInput
                    value={validForm.phone}
                    country='mx'
                    containerStyle={{ width: '100%' }}
                    inputStyle={{
                      width: '100%',
                      height: '48px',
                      borderRadius: '8px',
                      border: '1px solid #b9cad0',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    onChange={(e) => handleValidation('tel', e)}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='8px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={ubicacion} />
                <Text size='28px' weight='800' color='#0D3564'>
                  Dirección
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Código postal
                  </Text>
                  <StyledInput
                    value={validForm.postalCode}
                    type='text'
                    placeholder='05570'
                    onChange={(e) => handleValidation('postalCode', e)}
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Estado/Provincia/Región
                  </Text>
                  <StyledInput
                    value={validForm.region}
                    type='text'
                    placeholder='Ciudad de México'
                    onChange={(e) =>
                      setValidForm({ ...validForm, region: e.target.value })
                    }
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Ciudad
                  </Text>
                  <StyledInput
                    value={validForm.city}
                    type='text'
                    placeholder='Ciudad de México'
                    onChange={(e) =>
                      setValidForm({ ...validForm, city: e.target.value })
                    }
                  />
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Colonia
                  </Text>
                  <StyledSelect
                    value={validForm.colonia}
                    onChange={(e) => onChangeColony(e.target.value)}
                  >
                    {colonies?.length ? (
                      <>
                        <option selected>selecciona una colonia</option>
                        {colonies?.map((item) => {
                          return (
                            <option value={item.settlement_name}>
                              {item.settlement_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      validForm.colonia && (
                        <option selected>{validForm.colonia}</option>
                      )
                    )}
                  </StyledSelect>
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Calle
                  </Text>
                  <StyledInput
                    value={validForm.street}
                    type='text'
                    placeholder='Caleta'
                    onChange={(e) =>
                      setValidForm({ ...validForm, street: e.target.value })
                    }
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número Ext
                  </Text>
                  <StyledInput
                    value={validForm.extNumber}
                    type='text'
                    placeholder='500'
                    onChange={(e) =>
                      setValidForm({ ...validForm, extNumber: e.target.value })
                    }
                  />
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número Int
                  </Text>
                  <StyledInput
                    type='text'
                    value={validForm.intNumber}
                    placeholder='500'
                    onChange={(e) =>
                      setValidForm({ ...validForm, intNumber: e.target.value })
                    }
                  />
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Agregar instrucciones de entrega
                  </Text>
                  <StyledInput
                    type='text'
                    value={validForm.comments}
                    placeholder='Referencia del domicilio'
                    onChange={(e) =>
                      setValidForm({ ...validForm, comments: e.target.value })
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            //Input container
            direction='row'
            gap='4px'
            width='100%'
            justify='flex-end'
            mobile='justify-content: center;'
          >
            <Button
              disabled={
                validEmail &&
                validTel &&
                validPostalCode &&
                validForm.phone !== '' &&
                validForm.email !== '' &&
                validForm.region !== '' &&
                validForm.city !== '' &&
                validForm.colonia !== '' &&
                validForm.street !== '' &&
                validForm.extNumber !== ''
                  ? false
                  : true
              }
              onClick={() => {
                setSend(true);
                saveProgress(2, 'shipping_information', validForm);
              }}
            >
              Continuar
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            width='100%'
            direction='column'
            gap='40px'
            extras='border-top: 0.74px solid rgba(0, 0, 0, 0.35);'
            padding='64px 0 40px'
          >
            <Text size='20px' color='#14181A' textAlign='center'>
              Para continuar, por favor ingresa los datos que se te piden a
              continuación para ingresar a tu cuenta
            </Text>
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
            >
              <LogInForm isLoginInCart />
            </Flex>
          </Flex>
          {/* <Flex
            //Input container
            direction='row'
            gap='4px'
            width='100%'
            justify='flex-end'
            mobile='justify-content: center;'
          >
            <Button>Continuar</Button>
          </Flex> */}
        </>
      )}
    </>
  );
};

export default DatosEnvio;
