/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_DETAIL } from '../../../graphql/Query';
import { Button, Flex, Text } from '../../styles/common.styles';
import Nav from '../common/nav';
import NavLog from '../common/NavbarMktp/NavLog';
import { Finder } from '../Store/Finder/Finder';
import Footer from '../common/footer';
import styled from 'styled-components';
import { url } from '../../api';
const downArrow = `${url}downArrow.svg`;
import { Link } from 'react-router-dom';
import { formatNumberWithTwoDecimals } from '../../../helpers/shopCart';
import useShoppingCart from '../../../hooks/useShoppingCart';

const noAvailable = `${url}noAvailable.svg`;
const DetailItemComponent = ({
  item = {
    qty: 1,
  },
}) => {
  const history = useHistory();
  const location = useLocation();
  const { data } = location.state || {};
  const email = sessionStorage.getItem('auxEmail');
  const [select, setSelect] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [details, setDetails] = useState(true);
  const [previousId, setPreviousId] = useState(null);

  // useEffect(() => {
  //   setQuantity(item.qty);
  // }, [item.qty]);

  useEffect(() => {
    const currentQuantity = itemsShoppingCart.filter(
      (item) => item.id === details?.id_autopart?.toString()
    );
    console.log({ currentQuantity });
    if (currentQuantity.length > 0) {
      setQuantity(currentQuantity[0].qty);
    }
  }, [details]);

  const addQty = () => {
    const newQuantity = quantity + 1;
    if (newQuantity <= data.stock) {
      setQuantity(newQuantity);
      updateItemsQuantity(
        { ...details, id: details.id_autopart.toString() },
        newQuantity
      );
    }
  };

  const substractQty = () => {
    const newQuantity = quantity - 1;
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
      updateItemsQuantity(
        { ...details, id: details.id_autopart.toString() },
        newQuantity
      );
    }
  };

  const [getDetails] = useLazyQuery(GET_DETAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setDetails(data.getAutopartDetail.data);
    },
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.pathname.split('/').pop();

    if (id && id !== previousId) {
      getDetails({
        variables: {
          id: id,
        },
      });
      setPreviousId(id);
    }
  }, [getDetails, previousId]);

  const { updateItemsCart, itemsShoppingCart, updateItemsQuantity } =
    useShoppingCart();

  const addItem = (item, redirect) => {
    const isInCart = itemsShoppingCart?.filter(
      (cart) => cart.id === item.id_autopart.toString()
    );
    const infoPart = [...itemsShoppingCart];

    if (isInCart && isInCart.length > 0) {
      if (redirect) {
        history.push(`/store/cart`);
      } else {
        updateItemsQuantity(
          isInCart[0],
          isInCart[0].qty + 1 <= isInCart[0].existence
            ? isInCart[0].qty + 1
            : isInCart[0].existence
        );
        // alert('Se agrego al carrito');
        return;
      }
    } else {
      // alert('Se agrego al carrito');
      infoPart.push({
        id: item.id_autopart,
        name: item.repair,
        img: item?.image || `${url}noAvailable.svg`,
        existence: item?.stock,
        partNumber: item?.id_part,
        price: `$${item?.final_price}`,
        id_uac: item?.id_uac,
        qty: quantity,
        description: item.repair,
      });
    }
    if (redirect) {
      history.push(`/store/cart`);
    }
    updateItemsCart(infoPart);
  };

  return (
    <>
      <Nav store />
      <Finder redirect={true} />
      <Flex
        direction='column'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        padding='80px'
        gap='40px'
        mobile='padding: 40px 16px;'
        margin='0 auto'
      >
        <Text
          family='Roboto'
          size='24px'
          weight='600'
          color='#022741'
          decoration='underline'
          width='100%'
          cursor='pointer'
        >
          <Link to='/store' style={{ color: '#022741' }}>
            <img
              src={downArrow}
              alt=''
              style={{ transform: 'rotate(90deg)', margin: '0 40px 0 0' }}
            />
            Regresar a resultados{' '}
          </Link>
        </Text>

        <Flex
          width='100%'
          direction='row'
          gap='32px'
          mobile='flex-direction: column;'
          padding='64px 0 0'
          align='flex-start'
        >
          <ImgContainer>
            <ImgStyled src={details.image || noAvailable} alt='' />
          </ImgContainer>
          <Flex width='50%' mobile='width: 100%;' gap='64px' direction='column'>
            <Flex width='100%' direction='column' gap='16px'>
              <Text color='#0D3564' weight='700' family='Inter' size='18px'>
                Existencia:
                <span style={{ fontWeight: '700', color: '#32B7D7' }}>
                  &nbsp;{details.stock}
                </span>
              </Text>
              <Text color='#55646A' weight='400' family='Inter' size='20px'>
                {details.repair}
              </Text>
              <Text color='#55646A' weight='400' family='Inter' size='16px'>
                No. Parte: {details.id_part}
              </Text>
            </Flex>
            <Flex
              width='100%'
              padding='32px'
              bg='rgba(239, 243, 248, 0.50)'
              direction='column'
              gap='32px'
            >
              <Flex width='100%' direction='row' gap='24px'>
                <Text
                  family='Inter'
                  size='18px'
                  color='#0E79B4'
                  style={{
                    borderBottom: select === true ? '1px solid #0E79B4' : null,
                    padding: '0 0 16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelect(true)}
                >
                  Detalle de producto
                </Text>
                <Text
                  family='Inter'
                  size='18px'
                  color='#0E79B4'
                  style={{
                    borderBottom: select === false ? '1px solid #0E79B4' : null,
                    padding: '0 0 16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelect(false)}
                >
                  Ficha técnica
                </Text>
              </Flex>
              {select ? (
                <>
                  <Text family='Inter' size='16px' color='#55646A'>
                    {details.repair}
                  </Text>
                </>
              ) : (
                <>
                  <Table>
                    <tbody>
                      <TableRow>
                        <TableCell>Marca</TableCell>
                        <TableCell>{details.name_brand}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Altura</TableCell>
                        <TableCell>
                          {details.height} cm
                          {/* {details.heigth_measure} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ancho</TableCell>
                        <TableCell>
                          {details.width} cm
                          {/* {details.width_measure} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Peso</TableCell>
                        <TableCell>
                          {details.weight} kg
                          {/* {details.weight_measure} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Longitud</TableCell>
                        <TableCell>
                          {details.length} cm
                          {/* {details.length_measure} */}
                        </TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                </>
              )}
            </Flex>
            <Flex
              width='100%'
              wrap
              gap='32px'
              extras='border-bottom: 1px solid #8299A3;'
              padding='0 0 8px'
              justify='space-between'
            >
              <Flex
                direction='row'
                width='fit-content'
                gap='32px'
                align='center'
              >
                <Text color='#0D3564' weight='700'>
                  Cantidad
                </Text>
                <Flex
                  direction='row'
                  gap='15px'
                  width='fit-content'
                  align='center'
                >
                  <StyledButton onClick={() => substractQty()}>-</StyledButton>{' '}
                  <Text family='Nunito Sans' weight='600' color='#55646A'>
                    {quantity}
                  </Text>
                  <StyledButton onClick={() => addQty()}>+</StyledButton>
                </Flex>
              </Flex>
              <Text color='#0E79B4' weight='700' family='Mulish' size='24px'>
                <span
                  style={{
                    fontWeight: '400',
                    color: '#55646A',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Subtotal:{' '}
                </span>
                &nbsp; $
                {formatNumberWithTwoDecimals(details.final_price * quantity)}{' '}
                MXN
              </Text>
            </Flex>
            <Flex
              gap='24px'
              extras='flex-wrap: wrap-reverse;              '
              align='center'
              mobile='flex-direction: column-reverse; align-items: center;'
              width='100%'
            >
              <Text
                color='#0D3564'
                weight='700'
                family='Mulish'
                size='16px'
                extras='padding: 8px 0'
                width='190px'
                textAlign='center'
                style={{ cursor: 'pointer' }}
              >
                Comprar al mayoreo
              </Text>
              <Flex
                gap='24px'
                align='center'
                mobile='flex-direction: column; width: 100%;'
              >
                <Button
                  secondary
                  mobile='width: 100%;'
                  width='190px'
                  onClick={() => {
                    addItem(details, true);
                  }}
                >
                  Comprar ahora
                </Button>
                <Button
                  mobile='width: 100%;'
                  width='190px'
                  onClick={() => {
                    addItem(details);
                  }}
                >
                  Agregar al carrito
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default DetailItemComponent;

const ImgContainer = styled.div`
  margin: 80px 0 0;
  width: 50%;
  min-height: 200px;
  max-height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    height: 320px;
    width: 100%;
    max-height: 100%;
  }
`;

const ImgStyled = styled.img`
  max-height: 100%;
  height: auto;
  width: auto;
  max-width: 100%;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #ffffff;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: rgba(239, 243, 248, 0.5);
  }

  &:nth-child(even) {
    background-color: #ffffff;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  border: 0;

  &:nth-child(odd) {
    color: #55646a;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  &:nth-child(even) {
    color: #55646a;
    /* COMMON/Body */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

const StyledButton = styled.button`
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 1.5rem;
  box-sizing: border-box;
  border: 0;
  border-radius: 999px;
  color: #55646a;
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: #e6f6fa;
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`;
