import styled from 'styled-components';

// Importa styled-components u otra biblioteca de estilos que estés utilizando

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5); /* Fondo negro difuminado con opacidad */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000; /* Asegura que el fondo esté por encima del contenido */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 684px;
  padding: 56px;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  border-radius: 16px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  overflow-y: auto;
`;

export const MainTitle = styled.h2`
  color: var(--primarydeep, #0d3564);
  text-align: center;

  /* DESKTOP/H3 */
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  margin: 0;
`;

export const Subtitle = styled.p`
  color: #171b1c;
  width: 100%;
  /* DESKTOP/H5 */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  text-align: center;
`;

export const ButtonCargar = styled.button`
  display: flex;
  width: 212px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--primarydeep, #0d3564);
  color: var(--neutral-white, #fff);
  border-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
