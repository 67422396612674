import React, { useState } from 'react';

//styles
import {
  MainContainer,
  ButtonCrear,
  Imagen,
  InputContainer,
  InputDireccion,
  InputLabel,
  InputRowContainer,
  Title,
  TitleContainer,
  ButtonCointainer,
} from './DireccionForm.styles';
import { Flex, StyledSelect } from '../../../styles/common.styles';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../../../../redux/reducers/direccion.reducer';
import { ADD_USER } from '../../../../graphql/Mutation';
import { url } from '../../../api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_COLONY_BY_CP } from '../../../../graphql/Query';
import {
  LabelGeneral,
  SelectAlta,
} from '../DatosGenerales/DatosGenerales.styles';
import { useEffect } from 'react';
const DownArrow = `${url}downArrow.svg`;
//assets
const ubicacion = `${url}ubicacion.svg`;
const contacto = `${url}contacto.svg`;
const flag = `${url}flag.svg`;

export default function DireccionForm({
  setShowDistribuidores,
  reloadUsers,
  missingFields,
  createClick,
  setCreateClick,
  setMissingFields,
  setLoading,
  setClearData,
  clearData,
  tempData,
  mobile,
  form,
  setForm,
  saveUser,
  missingData,
}) {
  const formData = useSelector((state) => state.formData); // Acceder al estado formData de Redux
  const datosGenerales = useSelector(
    (state) => state.rootReducers.datosGenerales
  );

  const dispatch = useDispatch();

  const [formDataLocal, setFormDataLocalLocal] = useState({
    paisRegion: 'México',
    calle: '',
    numeroExterior: '',
    numeroInterior: '',
    codigoPostal: '',
    correoElectronico: '',
    numeroCelular: '',
    selectedColony: 0,
    editableUser: false,
  });

  const [colonies, setColonies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [getColony] = useLazyQuery(GET_COLONY_BY_CP, {
    fetchPolicy: 'no-cache',
    onCompleted({ getColony }) {
      const parsedInfo = JSON.parse(getColony.response);
      setColonies(parsedInfo);
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleFieldChange = (field, value) => {
    // setFormDataLocalLocal((prevFormData) => ({
    //   ...prevFormData,
    //   [field]: value,
    // }));
    // dispatch(setFormData({ ...formData, [field]: value }));
    // console.log(value, missingFields);
    /**---validaciones--- */
    // if (field === 'calle') {
    //   if (value && value !== '') {
    //     setMissingFields({
    //       ...missingFields,
    //       street: false,
    //     });
    //   } else {
    //     setMissingFields({
    //       ...missingFields,
    //       street: true,
    //     });
    //   }
    // }

    // if (field === 'selectedColony') {
    //   if (value && value !== '') {
    //     setMissingFields({
    //       ...missingFields,
    //       selectedColony: false,
    //     });
    //   } else {
    //     setMissingFields({
    //       ...missingFields,
    //       selectedColony: true,
    //     });
    //   }
    // }
    // if (field === 'numeroExterior') {
    //   if (value && value !== '') {
    //     setMissingFields({
    //       ...missingFields,
    //       numberOutside: false,
    //     });
    //   } else {
    //     setMissingFields({
    //       ...missingFields,
    //       numberOutside: true,
    //     });
    //   }
    // }

    if (field === 'codigoPostal') {
      setForm({
        ...form,
        codigoPostal: value,
        selectedColony: undefined,
      });
      const newValue = value.replace(/[^\d]/g, '').slice(0, 5);
      value = newValue;
      if (newValue.length === 5) {
        setForm({
          ...form,
          codigoPostal: newValue,
          selectedColony: undefined,
        });
        setFormDataLocalLocal({
          ...formDataLocal,
          codigoPostal: newValue,
          selectedColony: 0,
        });
        getColony({
          variables: {
            cp: newValue,
          },
        });
        setMissingFields({
          ...missingFields,
          cp: false,
        });
      } else {
        setColonies([]);
        setMissingFields({
          ...missingFields,
          cp: true,
          selectedColony: true,
        });
      }
    }

    // if (field === 'correoElectronico') {
    //   if (value && value !== '') {
    //     setMissingFields({
    //       ...missingFields,
    //       mail: false,
    //     });
    //   } else {
    //     setMissingFields({
    //       ...missingFields,
    //       mail: true,
    //     });
    //   }
    // }

    // if (field === 'numeroCelular') {
    //   if (value && value !== '') {
    //     setMissingFields({
    //       ...missingFields,
    //       phone: false,
    //     });
    //   } else {
    //     setMissingFields({
    //       ...missingFields,
    //       phone: true,
    //     });
    //   }
    // }
  };

  const [createUser] = useMutation(ADD_USER, {
    onCompleted: ({ registerUser }) => {
      const parsed = JSON.parse(registerUser.response);
      if (parsed.response === 'El correo ya esta registrado') {
        alert('El correo ya esta registrado');
        setLoading(false);
      } else {
        setFormDataLocalLocal({
          paisRegion: 'México',
          calle: '',
          numeroExterior: '',
          numeroInterior: '',
          codigoPostal: '',
          correoElectronico: '',
          numeroCelular: '',
          editableUser: false,
        });
        setCreateClick(false);
        setClearData(true);
        const parse = JSON.parse(registerUser.response);
        const idFather = parse.id_uac_d1;
        reloadUsers(idFather);
        setLoading(false);
      }
      setLoading(false);
    },
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
      setLoading(false);
    },
  });

  //aqui
  const addUserMutation = () => {
    const infoUser = Object.assign(formDataLocal, datosGenerales);
    const valida = Object.values(missingFields);
    if (valida.includes(true)) {
      setCreateClick(true);
    } else {
      setLoading(true);
      createUser({
        variables: { data: JSON.stringify(infoUser) },
      });

      // setShowDistribuidores(true)
    }
  };

  // const getInfoUser = async (tempData) => {
  //   if (tempData?.direccionesDistribuidors) {
  //     setMissingFields({
  //       nameGroup: false,
  //       nameUser: false,
  //       brands: false,
  //       street: false,
  //       numberOutside: false,
  //       cp: false,
  //       mail: false,
  //       phone: false,
  //       selectGroup: false,
  //       typeUser: false,
  //       idDistribuidor: false,
  //       selectd2: false,
  //       selectd3: false,
  //     });
  //     setIsEditing(true);
  //     await getColony({
  //       variables: {
  //         cp: tempData?.direccionesDistribuidors[0]?.zip,
  //       },
  //     });
  //     setFormDataLocalLocal({
  //       paisRegion: 'México',
  //       calle: tempData?.direccionesDistribuidors[0]?.street,
  //       numeroExterior: tempData?.direccionesDistribuidors[0]?.number,
  //       numeroInterior: tempData?.direccionesDistribuidors[0]?.number_inside,
  //       codigoPostal: tempData?.direccionesDistribuidors[0]?.zip,
  //       correoElectronico: tempData.mail,
  //       numeroCelular: tempData.phone,
  //       selectedColony: tempData?.direccionesDistribuidors[0]?.id_colony,
  //       editableUser: true,
  //     });
  //   } else {
  //     setColonies([]);
  //     setIsEditing(false);
  //     setMissingFields({
  //       nameUser: true,
  //       brands: true,
  //       street: true,
  //       numberOutside: true,
  //       cp: true,
  //       mail: true,
  //       phone: true,
  //       selectedColony: true,
  //       nameGroup: true,
  //       selectGroup: false,
  //       typeUser: false,
  //       idDistribuidor: false,
  //       selectd2: false,
  //       selectd3: false,
  //     });
  //   }
  // };

  useEffect(() => {
    if (clearData) {
      setFormDataLocalLocal({
        paisRegion: 'México',
        calle: '',
        numeroExterior: '',
        numeroInterior: '',
        codigoPostal: '',
        correoElectronico: '',
        numeroCelular: '',
        editableUser: false,
      });
    }
  }, [clearData]);

  // useEffect(() => {
  //   getInfoUser(tempData);
  // }, [tempData]);

  const [openDrop, setOpenDrop] = useState(false);

  useEffect(() => {
    !mobile && setOpenDrop(true);
    console.log(formDataLocal.selectedColony);
  }, [mobile]);

  return (
    <MainContainer>
      <Flex
        direction='row'
        justify='space-between'
        width='100%'
        margin={openDrop && '0 0 24px'}
        onClick={() => mobile && setOpenDrop(!openDrop)}
        style={{ cursor: 'pointer' }}
      >
        <TitleContainer>
          <Imagen src={ubicacion} alt='' />
          <Title>Dirección</Title>
        </TitleContainer>
        {mobile && (
          <img
            alt='arrow'
            src={DownArrow}
            style={{ transform: openDrop && 'rotate(180deg)' }}
          />
        )}
      </Flex>
      {openDrop && (
        <>
          <InputRowContainer>
            <InputContainer>
              <InputLabel>País o región</InputLabel>
              <InputDireccion
                value={form.paisRegion || ''}
                onChange={(e) => {
                  setForm({ ...form, paisRegion: e.target.value });
                  handleFieldChange('paisRegion', e.target.value);
                }}
                disabled={true}
                style={{ background: '#DCDEDE' }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Calle</InputLabel>
              <InputDireccion
                disabled={form?.isD1}
                value={form.calle || ''}
                onChange={(e) => {
                  setForm({ ...form, calle: e.target.value });
                  handleFieldChange('calle', e.target.value);
                }}
                placeholder='Inserta el nombre de tu calle'
              />
              {createClick && missingData?.calle && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
          </InputRowContainer>

          <InputRowContainer>
            <InputContainer>
              <InputLabel>Número exterior</InputLabel>
              <InputDireccion
                disabled={form?.isD1}
                value={form.numeroExterior || ''}
                onChange={(e) => {
                  setForm({ ...form, numeroExterior: e.target.value });
                  handleFieldChange('numeroExterior', e.target.value);
                }}
                placeholder='Ingresa el número ext'
              />
              {createClick && missingData?.numeroExterior && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
            <InputContainer>
              <InputLabel>Número interior (opcional)</InputLabel>
              <InputDireccion
                disabled={form?.isD1}
                value={form.numeroInterior || ''}
                onChange={(e) => {
                  setForm({ ...form, numeroInterior: e.target.value });
                  handleFieldChange('numeroInterior', e.target.value);
                }}
                placeholder='Ingresa el número int'
              />
              {createClick && missingData?.numeroInterior && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
          </InputRowContainer>

          <InputRowContainer>
            <InputContainer style={{ width: '100%' }}>
              <InputLabel>Código postal</InputLabel>
              <InputDireccion
                disabled={form?.isD1}
                value={form.codigoPostal || ''}
                onChange={(e) => {
                  handleFieldChange('codigoPostal', e.target.value);
                }}
                pattern='[0-9]{1, 5}'
                title='Ingresa un código postal válido (solo números, máximo 5 dígitos)'
                required
                placeholder='Ej. 01480'
              />
              {formDataLocal.codigoPostal &&
                !/^[0-9]{1,5}$/.test(formDataLocal.codigoPostal) && (
                  <span style={{ color: 'red' }}>
                    El código postal debe contener solo números y tener un
                    máximo de 5 dígitos.
                  </span>
                )}
              {createClick && missingData?.codigoPostal && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
            <InputContainer style={{ width: '100%' }}>
              <LabelGeneral>Selecciona tu colonia </LabelGeneral>

              <StyledSelect
                disabled={form?.isD1}
                value={form.selectedColony || ''}
                onChange={(e) => {
                  setForm({ ...form, selectedColony: e.target.value });
                  handleFieldChange('selectedColony', e.target.value);
                }}
                style={{
                  color: formDataLocal.selectedColony == 0 ? 'grey' : null,
                }}
                //Cambio color select
              >
                <option disabled selected value=''>
                  {' '}
                  Selecciona una opción
                </option>
                {colonies?.length &&
                  colonies?.map((item) => {
                    return (
                      <option value={item.id}>{item.settlement_name}</option>
                    );
                  })}
              </StyledSelect>
              {createClick && missingData?.selectedColony && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
          </InputRowContainer>

          <TitleContainer style={{ margin: '40px 0 24px' }}>
            <Imagen src={contacto} alt='' />
            <Title>Datos de contacto</Title>
          </TitleContainer>

          {/* <InputRowContainer>
        <InputContainer>
          <InputLabel>Número exterior</InputLabel>
          <InputDireccion placeholder='Ingresa el número ext'/>
        </InputContainer>
        <InputContainer>
          <InputLabel>Número interior (opcional)</InputLabel>
          <InputDireccion placeholder='Ingresa el número int'/>
        </InputContainer>
      </InputRowContainer> */}

          <InputRowContainer>
            <InputContainer>
              <InputLabel>Correo electrónico</InputLabel>
              <InputDireccion
                disabled={form?.isD1}
                type='email' // Set the input type to 'email' for email validation
                value={form.correoElectronico || ''}
                onChange={(e) => {
                  setForm({ ...form, correoElectronico: e.target.value });
                  handleFieldChange('correoElectronico', e.target.value);
                }}
                placeholder='Ingresa tu correo electrónico'
                pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}'
                title='Por favor ingresa un correo electrónico válido'
                required
              />
              {formDataLocal.correoElectronico &&
                !/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(
                  formDataLocal.correoElectronico
                ) && (
                  <span style={{ color: 'red' }}>
                    Ingresa un correo electrónico válido.
                  </span>
                )}
              {createClick && missingData?.correoElectronico && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
            <InputContainer style={{ position: 'relative' }}>
              <InputLabel>Número celular</InputLabel>
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    width: '20px',
                    height: '15px',
                    position: 'absolute',
                    bottom: '15px',
                    left: '10px',
                  }}
                  src={flag}
                  alt=''
                />
                <InputDireccion
                  disabled={form?.isD1}
                  style={{ paddingLeft: '35px' }}
                  type='tel'
                  pattern='[0-9]{1,10}'
                  value={form.numeroCelular || ''}
                  title='Número de celular debe tener entre 1 y 10 dígitos numéricos'
                  required
                  maxLength={10}
                  onChange={(e) => {
                    setForm({ ...form, numeroCelular: e.target.value });
                    handleFieldChange('numeroCelular', e.target.value);
                  }}
                  placeholder='+52'
                />
              </div>
              {formDataLocal.numeroCelular &&
                !/^[0-9]{1,10}$/.test(formDataLocal.numeroCelular) && (
                  <span style={{ color: 'red' }}>
                    Ingresa un número de celular válido (solo números, máximo 10
                    dígitos).
                  </span>
                )}
              {createClick && missingData?.numeroCelular && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>
          </InputRowContainer>

          <ButtonCointainer>
            <ButtonCrear
              disabled={form?.isD1}
              onClick={() => {
                saveUser();
                // addUserMutation()
              }}
            >
              {form?.editingUser ? 'Guardar cambios' : 'Crear usuario'}
            </ButtonCrear>
          </ButtonCointainer>
        </>
      )}
    </MainContainer>
  );
}
