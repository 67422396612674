import React, { useEffect, useState } from 'react';
import Footer from '../../components/common/footer';
import SideNav from '../../components/AddUser/SideNav/SideNav';
import { MainContainer, SubContainer } from './AddUser.styles';
import NavAltaUsuario from '../../components/common/NavAltaUsuario/NavAltaUsuario';
import Descuentos from '../Descuentos/Descuentos';
import Configuracion from '../Configuracion/Configuracion';
import AltaDistribuidor from '../../components/AddUser/AltaDistribuidor/AltaDistribuidor';
import AdminMtk from '../AdminMkt/AdminMtk';

export default function AddUser() {
  const [openNav, setOpenNav] = useState(false);
  const [selectedSections, setSelectedSections] = useState(() => {
    const storedSections = localStorage.getItem('sec');
    return storedSections ? JSON.parse(storedSections) : {
      dashboard: true,
      configuracion: false,
      cuenta: false,
      marketPlace: false,
      altaDistribuidor: false,
      descuentos: false,
      cerrarSesion: false,
      adminMkt: false,
    };
  });

  const handleSectionClick = (section) => {
    const updatedSections = { ...selectedSections };
    Object.keys(updatedSections).forEach((key) => {
      updatedSections[key] = key === section;
    });
    setSelectedSections(updatedSections);
    setOpenNav(false);
  };

  function handleSidebarClose() {
    setOpenNav(false);
  }

  const manejarClicFuera = (e) => {
    const divChild = document.getElementById('child');

    if (divChild && !divChild.contains(e.target)) {
      // Clic fuera del div child, ocultar el div
      setOpenNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', manejarClicFuera);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', manejarClicFuera);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('sec', JSON.stringify(selectedSections));
  }, [selectedSections]);

  return (
    <MainContainer>
      <NavAltaUsuario setOpenNav={() => setOpenNav(!openNav)} />
      <SubContainer>
        <SideNav
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections} 
          handleSectionClick={handleSectionClick}
          open={openNav}
          handleSidebarClose={handleSidebarClose}
        />
        {selectedSections.altaDistribuidor ? (
          <AltaDistribuidor setClose={() => setOpenNav(false)} />
        ) : null}
        {selectedSections.descuentos ? (
          <Descuentos setClose={() => setOpenNav(false)} />
        ) : null}
        {selectedSections.configuracion ? (
          <Configuracion setClose={() => setOpenNav(false)} />
        ) : null}
        {selectedSections.dashboard ? (
          <AdminMtk isAdminDashboard setClose={() => setOpenNav(false)} />
        ) : null}
        {selectedSections.adminMkt ? (
          <AdminMtk
            isAdminDashboard={false}
            setClose={() => setOpenNav(false)}
          />
        ) : null}
      </SubContainer>
      <Footer />
    </MainContainer>
  );
}