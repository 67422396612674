import styled from 'styled-components';
import { Flex, Text } from '../../../../styles/common.styles';
import { useEffect, useState } from 'react';
import { Divider } from '../../../common/ModalMenu/ModalMenu.styles';
import { formatNumberWithTwoDecimals, stringToNumber } from '../../../../../helpers/shopCart';

export const ItemCardContainer = styled.div`
  background: #fff;
  max-width: 302px;
  width: 100%;
  height: 464px;
  height: fit-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  background-color: #fff;
  @media (min-width: 1023px) {
    height: 280px;
    max-width: 100%;
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  width: 180px;
  height: 180px;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1023px) {
    height: 163px;
    width: 163px;
  }
`;

const ImgStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  cursor: pointer;
`;

const StyledButton = styled.button`
  cursor: not-allowed;
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 1.5rem;
  box-sizing: border-box;
  border: 0;
  border-radius: 999px;
  color: #55646a;
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: #e6f6fa;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`;
export const CardArticuloPaqueteria = ({ item, handleBuy, costoEnvio }) => {
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(item.qty);
  }, [item.qty]);

  const addQty = () => {
    const newQuantity = quantity + 1;
    if (newQuantity <= item.existence) {
      setQuantity(newQuantity);
    }
  };

  const substractQty = () => {
    const newQuantity = quantity - 1;
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };

  useEffect(() => {
    //! Here: effect a ejecutar en cambio de qty
  }, [quantity]);

  return (
    <>
      <ItemCardContainer>
        <ImgContainer>
          <ImgStyled alt='' src={item.img} />
        </ImgContainer>
        <Flex direction='column' width='100%' height='100%' gap='16px'>
          <Flex
            direction='row'
            width='100%'
            height='100%'
            gap='32px'
            mobile='flex-direction: column;'
          >
            <Flex
              direction='column'
              width='65%'
              height='100%'
              gap='16px'
              mobile='width: 100%;'
            >
              <Flex
                direction='row'
                align='center'
                justify='space-between'
                width='100%'
                gap='1rem'
              >
                <Text color='#0D3564' weight='700'>
                  No. Parte:{' '}
                  <span style={{ fontWeight: '400' }}>{item.partNumber}</span>
                </Text>
              </Flex>
              <Text
                width='100%'
                family='Inter'
                color='#55646A'
                extras='max-height: 76px; overflow: hidden; text-overflow: ellipsis;'
              >
                {item.description}
              </Text>
            </Flex>
            <Flex
              direction='column'
              width='35%'
              height='100%'
              mobile='width: 100%; max-width: 280px;'
            >
              <Text color='#0D3564' weight='700'>
                Precio
              </Text>
              <Flex
                direction='row'
                width='100%'
                height='100%'
                align='center'
                justify='space-between'
                gap='1rem'
              >
                <Text color='#55646A' weight='400' family='Inter' size='14px'>
                  Subtotal:
                </Text>
                <Text color='#0E79B4' weight='700' family='Inter' sixze='18px'>
                ${formatNumberWithTwoDecimals(stringToNumber(item.price) * quantity)}{' '}MXN
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
        </Flex>
      </ItemCardContainer>
    </>
  );
};
