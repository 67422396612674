/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
//styles
import {
  MainContainer,
  Title,
  FinderContainer,
  ItemMenu,
  Selection,
  TitleWhite,
  InputText,
  ButtonFinder,
  Divisor,
  Bgcolor,
  MobileDivisor,
} from './Finder.styles';
import Loader from '../../common/Loader';
import { url } from '../../../api';
import {
  GET_ALL_BRANDS,
  GET_ALL_CATEGORIES,
  GET_PRODUCTS_BY_FILTER,
} from '../../../../graphql/Query';
import { useQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { Text } from '../../../styles/common.styles';
import Search from '../../../assets/search.svg';
//assts
const bgimg = `${url}imageHero.png`;
const heromb = `${url}herombNew.svg`;

export const Finder = ({
  reloadData,
  redirect = false,
  setPages,
  page,
  setTotalResults,
}) => {
  const history = useHistory();
  const [listBrands, setListBrand] = useState([]);
  const [listCategories, setCategories] = useState([]);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [part, setPart] = useState('');
  const [loading, setLoading] = useState(false);

  useQuery(GET_ALL_BRANDS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getBrandsLp }) {
      const parsed = JSON.parse(getBrandsLp.response);
      setListBrand(parsed);
    },
  });

  useQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: 'no-cache',
    onCompleted({ getCategoriesLp }) {
      const parsed = JSON.parse(getCategoriesLp.response);
      setCategories(parsed);
    },
  });

  const [searchByFilter] = useLazyQuery(GET_PRODUCTS_BY_FILTER, {
    fetchPolicy: 'no-cache',
    onCompleted: (getProductsByFilter) => {
      setLoading(false);
      sessionStorage.setItem(
        'Result',
        JSON.stringify(getProductsByFilter.getProductsByFilter.data)
      );
      // setPages(getProductsByFilter.getProductsByFilter.totalPaginas);
      // setTotalResults(getProductsByFilter.getProductsByFilter.totalregistros);
      !redirect && reloadData(getProductsByFilter.getProductsByFilter.data);
      !redirect &&
        setPages(getProductsByFilter.getProductsByFilter.totalPaginas);
      redirect && history.push('/store');
    },
  });

  const handleSelect = (e) => {
    let valor = e.target.value;
    if (e.target.name === 'brand') {
      setBrand(e.target.value);
      const selectedOption = listBrands.find(
        (option) => option.id_catalog_brands === Number(valor)
      );
      sessionStorage.setItem('Brand', selectedOption?.name_brand);
    } else if (e.target.name === 'category') {
      setCategory(e.target.value);
      const selectedOption = listCategories.find(
        (option) => option.id_Categoria === Number(valor)
      );
      sessionStorage.setItem('Ctry', selectedOption?.Categorias);
    } else if (e.target.name === 'idpart') {
      setPart(e.target.value);
      sessionStorage.setItem('idPart', e.target.value);
    }
  };

  const handleSearch = () => {
    searchByFilter({
      variables: {
        categoryId: category,
        brandId: brand,
        part: part,
        pageNumber: page,
        pageSize: 12,
      },
    });
    setLoading(true);
    // setCategory('');
    // setPart('');
    // setBrand('');
  };

  useEffect(() => {
    sessionStorage.setItem('idPart', part);
  }, [part]);

  useEffect(() => {}, []);

  const clearFilters = () => {
    sessionStorage.removeItem('Brand');
    sessionStorage.removeItem('category');
    sessionStorage.removeItem('idPart');
    setCategory('');
    setPart('');
    setBrand('');
    console.log(brand);
  };

  useEffect(() => {
    console.log(brand, listBrands);

    return () => {
      // limpieza del efecto
    };
  }, [brand]);

  const isMobile = window.innerWidth <= 1023;
  return (
    <>
      <Loader open={loading} />
      <MainContainer
        style={{
          backgroundImage: `url(${isMobile ? heromb : bgimg})`,
          backgroundSize: 'cover',
        }}
      >
        <Bgcolor></Bgcolor>
        <Title>
          Encuentra la pieza ideal <br />{' '}
          <TitleWhite>para tu vehículo</TitleWhite>{' '}
        </Title>
        <FinderContainer>
          <ItemMenu>
            <Selection
              onChange={(e) => handleSelect(e)}
              name='brand'
              value={brand}
            >
              <option selected disabled value={''}>
                Marca
              </option>
              {listBrands.map((item) => (
                <option value={item.id_catalog_brands}>
                  {item.name_brand}
                </option>
              ))}
            </Selection>
          </ItemMenu>
          {/* <Divisor />
          <MobileDivisor />
          <ItemMenu>
            <Selection
              onChange={(e) => handleSelect(e)}
              name='category'
              value={category}
            >
              <option>Categoría</option>
              {listCategories.map((item) => (
                <option value={item.id_Categoria}>{item.Categorias}</option>
              ))}
            </Selection>
          </ItemMenu> */}
          <Divisor />
          <MobileDivisor />
          <ItemMenu>
            <InputText
              placeholder='Número de parte'
              onChange={(e) => handleSelect(e)}
              name='idpart'
              value={part}
            />
          </ItemMenu>
          <Divisor />
          <ItemMenu clean>
            <button
              style={{
                background: 'transparent',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
              onClick={clearFilters}
            >
              <Text familiy='Roboto' size='16px' weight='600' color='#32B7D7'>
                Limpiar
              </Text>
            </button>
          </ItemMenu>
          <ItemMenu clean>
            <ButtonFinder onClick={handleSearch}>
              <img alt='' src={Search} />
            </ButtonFinder>
          </ItemMenu>
        </FinderContainer>
        <FinderContainer style={{ background: 'none', padding: '8px 0' }}>
          <ItemMenu clean2>
            <ButtonFinder onClick={handleSearch}>
              <img alt='' src={Search} />
            </ButtonFinder>
          </ItemMenu>
          {/* --------------- */}
          <ItemMenu clean2>
            <Text
              style={{ width: '100%' }}
              textAlign='center'
              weight='700'
              color='#ffffff'
            >
              Limpiar
            </Text>
          </ItemMenu>
        </FinderContainer>
      </MainContainer>
    </>
  );
};
