/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../../../../../graphql/Mutation';
//styles
import {
  MainContainer,
  InputContainer,
  InputLabel,
  InputField,
  Mantener,
  Olvide,
  ButtonLogIn,
  Divider,
  Enlace,
  ContainerManter,
  CheckboxContainer,
  DividerContainer,
  IconUser,
  IconUserEye,
  Errormsg,
} from './LogInForm.styles';

import Modal1 from '../../../../../front2/components/PasswordRecoveryModals/Modal1.jsx';
import Modal2 from '../../../../../front2/components/PasswordRecoveryModals/Modal2.jsx';
//libreries
import { useHistory } from 'react-router-dom';
import Loader from '../../Loader';
import { url } from '../../../../api';
import useUser from '../../../../../hooks/useUser';
//assets
const IconUserInput = `${url}IconUser.svg`;
const EyeInput = `${url}EyeInput.svg`;
const lockInput = `${url}lockInput.svg`;
const eyeClosed = `${url}eyeClosed.svg`;
export default function LogInForm({ isLoginInCart }) {
  const { getInfoUser, getInfoUserDashboard } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [eyeOpen, setEyeOpen] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [badResquest, setBadRequest] = useState('');

  const history = useHistory();

  useEffect(() => {
    console.log('password: ', password, password.length);
    return () => {
      // limpieza del efecto
    };
  }, []);

  //! mock para loader-----
  const [loading, setLoading] = useState(false);
  const fetchData = async ({ success }) => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simula una carga de 2 segundos
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    } finally {
      setLoading(false);
      success();
    }
  };

  const handleContinue = () => {
    fetchData({
      success: () => {
        setOpenModal1(false);
        setOpenModal2(true);
      },
    });
  };

  const validateEmail = (stremail) => {
    const emailRegex =
      /^[A-Za-z0-9+]+(?:[._-][A-Za-z0-9]+)*@[A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;
    // const specialChar = /[!#$%^&*()_+\-=[\]{};':"\\|,<>/?]/;
    if (!emailRegex.test(stremail)) {
      setEmailError('El correo electrónico ingresado no es válido.');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = () => {
    if (password.length < 8 && password.length > 0) {
      setPasswordError(
        'Por favor, verifica tu contraseña e intenta nuevamente. Si olvidaste tu contraseña, puedes restablecerla haciendo clic en Olvidé mi contraseña'
      );
    } else {
      setPasswordError('');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value); // Calling validateEmail with the new value
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail(email); // Validate email first
    validatePassword(); // Validate password
    fetchData({
      success: () => {},
    });
    // If both email and password are valid, you can proceed with the login logic here.
    // For simplicity, let's just log the values for now.
    if (!emailError && !passwordError) {
    }
    login({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data.login.ok === true) {
        if (data.login.type === 'customer') {
          sessionStorage.setItem('token', data.login.token);
          sessionStorage.setItem('auxEmail', data.login.email);
          getInfoUser(email, isLoginInCart);
        }

        if (data.login.type === 'admin') {
          sessionStorage.setItem('token', data.login.token);
          sessionStorage.setItem('refreshToken', data.login.refreshToken);
          history.push('/admin/dashboard');
          getInfoUserDashboard(data.login.token);
        }
        // const typeUser = data.login.typeUser;
        // localStorage.setItem('tokenStore', data.login.token);
        // localStorage.setItem('refreshTokenStore', data.login.refreshToken);
        // history.push('/store');
        // localStorage.setItem('typeUser', typeUser);
        //  if (typeUser == 6) {
        //   sessionStorage.setItem('auxEmail', data.login.correo);
        //   sessionStorage.setItem('idFather2', data.login.father);
        //   sessionStorage.removeItem('idDist2');
        //   sessionStorage.setItem('idMarcaAut2', data.login.marca);
        //   sessionStorage.setItem('marcasFiltradas', data.login.marca);
        //   sessionStorage.setItem('idFather', data.login.father);

        // }
      } else if (data.login.ok == false) {
        setBadRequest(
          `El correo electrónico ingresado no está registrado o tu contraseña es errónea. Por favor, verifica que lo hayas escrito correctamente o regístrate para crear una cuenta.`
        );
      }
    },
    onError: (error) => {
      // alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  const handleKeyDown = (event) => {
    //función onEnter
    if (event.key === 'Enter') {
      // Lógica que deseas ejecutar cuando se presiona Enter
      if (!emailError && !passwordError) {
        handleSubmit(event);
      }
    }
  };

  // useEffect(() => {
  //   validatePassword();
  // }, [password]);

  return (
    <div>
      <Loader open={loading} />
      <Modal1
        open={openModal1}
        handleContinue={() => handleContinue()}
        close={() => setOpenModal1(false)}
      />
      <Modal2 open={openModal2} />
      <MainContainer>
        <InputContainer>
          <InputLabel>Correo electrónico</InputLabel>
          <InputField
            placeholder='Ingresa tu correo electrónico'
            value={email}
            onChange={handleEmailChange}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <IconUser src={IconUserInput} alt='Email Icon' />
        </InputContainer>
        {emailError && <Errormsg>{emailError}</Errormsg>}
        {badResquest && <Errormsg>{badResquest}</Errormsg>}
        <InputContainer>
          <InputLabel>Contraseña</InputLabel>
          <InputField
            placeholder='Ingresa tu contraseña'
            type={eyeOpen ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            onBlur={validatePassword} // Validate password on blur
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <IconUser src={lockInput} alt='Email Icon' />
          <IconUserEye
            src={eyeOpen ? EyeInput : eyeClosed}
            alt='Email Icon'
            onClick={() => setEyeOpen(!eyeOpen)}
          />
        </InputContainer>
        {passwordError && <Errormsg>{passwordError}</Errormsg>}
        <ContainerManter>
          <CheckboxContainer>
            <input type='checkbox' />
            <Mantener>Mantener sesión iniciada</Mantener>
          </CheckboxContainer>
          <Olvide onClick={() => setOpenModal1(true)}>
            Olvidé mi contraseña
          </Olvide>
        </ContainerManter>
        <ButtonLogIn
          disabled={emailError || passwordError}
          onClick={handleSubmit}
        >
          Iniciar sesión
        </ButtonLogIn>
        <DividerContainer>
          <Divider></Divider>
          <p>ó</p>
          <Divider></Divider>
        </DividerContainer>
        <Mantener>¿Aún no tienes una cuenta?</Mantener>
        <Enlace onClick={() => history.push('/create-account')}>
          Crear cuenta
        </Enlace>
      </MainContainer>
    </div>
  );
}
