import React, { useEffect, useState } from 'react';
//styles
import {
  GridContainer,
  ImagenSubtitle,
  ImagenTitle,
  MainContainer,
  Subtitle,
  SubtitleContainer,
  TextGrid,
  Title,
  TitleContainer,
  ImagenArrow,
  TitleGrid,
  // GridContainerRow,
  ContainImg,
  Imagenes,
  // TitleGridContain,
  Item15,
  Item5,
  Item10,
  Item20,
  ContainerTable,
} from './Distribuidores.styles';

//redux
import { useDispatch, useSelector } from 'react-redux';
import ModalDistribuidores from './ModalDistribuidores';
import { clearDatosGenerales } from '../../../../redux/reducers/datosGrales.reducer';
import { clearFormData } from '../../../../redux/reducers/direccion.reducer';
import { SelectAlta } from '../DatosGenerales/DatosGenerales.styles';
import { url } from '../../../api';
//assets
const camion = `${url}camion.svg`;
const downArrow = `${url}downArrow.svg`;
const edit = `${url}Editar.svg`;
const trash = `${url}delete.svg`;
export default function Distribuidores({
  allUsers,
  deleteUser,
  showDistribuidores,
  handleEditUser,
}) {
  const [expand, setExpand] = useState(false);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);
  const [users, setUsers] = useState([]);
  const [nameGroup, setNameGroup] = useState('');
  const formData = useSelector((state) => state.rootReducers.formData);
  const datosGenerales = useSelector(
    (state) => state.rootReducers.datosGenerales
  );
  const dispatch = useDispatch();

  const clearData = ({ idUser }) => {
    // Assuming you are using Redux to update the states
    // Dispatch actions to update datosGenerales and formData to empty or null values
    // For example:
    // dispatch(updateDatosGenerales(null));
    // dispatch(updateFormData(null));
    dispatch(clearDatosGenerales());
    dispatch(clearFormData());
    setShow(!show);
    setHide(!hide);
    deleteUser(idUser);
  };

  const getTypeUser = (type) => {
    switch (type) {
      case 5:
        return 'D4';
      case 4:
        return 'D3';
      case 3:
        return 'D2';
      case 2:
        return 'D1';
      default:
        return '';
    }
  };

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    console.log(users);
  }, [users]);

  return (
    <>
      <TitleContainer>
        <ImagenTitle src={camion} alt='' />
        <Title style={{ margin: 0 }}>Distribuidores</Title>
      </TitleContainer>

      <MainContainer>
        <SubtitleContainer>
          <TitleContainer>
            <ImagenSubtitle src={camion} alt='' />
            <Subtitle>Grupo {showDistribuidores}</Subtitle>
          </TitleContainer>
          <div>
            <ImagenArrow
              src={downArrow}
              alt=''
              onClick={() => setExpand(!expand)}
            />
          </div>
        </SubtitleContainer>
        <ContainerTable>
          {expand && (
            <>
              <GridContainer first>
                <Item5>
                  <TitleGrid>Tipo de usuario</TitleGrid>
                </Item5>
                <Item5>
                  <TitleGrid>Id distribuidor</TitleGrid>
                </Item5>
                <Item15>
                  <TitleGrid>Nombre de usuario</TitleGrid>
                </Item15>
                <Item20>
                  <TitleGrid>Correo electrónico</TitleGrid>
                </Item20>
                <Item15>
                  <TitleGrid>Número de contacto</TitleGrid>
                </Item15>
                <Item15>
                  <TitleGrid>Marca(s)</TitleGrid>
                </Item15>
                <Item15>
                  <TitleGrid>Dirección</TitleGrid>
                </Item15>
                <Item10>
                  <TitleGrid></TitleGrid>
                </Item10>
              </GridContainer>
              {users.map((item) => (
                <GridContainer>
                  <Item5>
                    <TextGrid>{getTypeUser(item.type_user)}</TextGrid>
                  </Item5>
                  <Item5>
                    <TextGrid>{item.id_distribuidor}</TextGrid>
                  </Item5>
                  <Item15>
                    <TextGrid>{item.name}</TextGrid>
                  </Item15>
                  <Item20>
                    <TextGrid>{item.mail}</TextGrid>
                  </Item20>
                  <Item15>
                    <TextGrid>{item.phone}</TextGrid>
                  </Item15>
                  <Item15>
                    <TextGrid>
                      <SelectAlta>
                        <option disabled selected value={0}>
                          {' '}
                          Marcas
                        </option>
                        {item?.users_brands &&
                          item?.users_brands?.map((item) => {
                            return (
                              <option value={item.catalog_brand.name_brand}>
                                {item.catalog_brand.name_brand ??
                                  'No disponible'}
                              </option>
                            );
                          })}
                      </SelectAlta>
                    </TextGrid>
                  </Item15>
                  <Item15>
                    <TextGrid style={{ textAlign: 'left', width: '100%' }}>
                      {`
                    ${
                      item?.direccionesDistribuidors[0]?.street
                        ? item?.direccionesDistribuidors[0]?.street
                        : ''
                    } 
                    ${
                      item?.direccionesDistribuidors[0]?.number
                        ? `#${item?.direccionesDistribuidors[0]?.number},`
                        : ''
                    } 
                    ${
                      item?.direccionesDistribuidors[0]?.number_inside
                        ? ', int' +
                          item?.direccionesDistribuidors[0]?.number_inside +
                          ','
                        : ''
                    }
                    ${
                      item?.direccionesDistribuidors[0]?.zip
                        ? `${item?.direccionesDistribuidors[0]?.zip},`
                        : ''
                    }
                    ${
                      item?.direccionesDistribuidors[0]?.colonia
                        ? `${item?.direccionesDistribuidors[0]?.colonia},`
                        : ''
                    }
                    ${
                      item?.direccionesDistribuidors[0]?.city
                        ? `${item?.direccionesDistribuidors[0]?.city},`
                        : ''
                    }
                    ${
                      item?.direccionesDistribuidors[0]?.state
                        ? `${item?.direccionesDistribuidors[0]?.state},`
                        : ''
                    }
                    México
                     `}
                    </TextGrid>
                  </Item15>
                  <Item10>
                    <ContainImg>
                      {' '}
                      <Imagenes
                        src={edit}
                        alt=''
                        onClick={() => {
                          handleEditUser(item, item.type_user === 2);
                        }}
                      />
                      {item.type_user !== 2 && (
                        <Imagenes
                          src={trash}
                          alt=''
                          onClick={() => {
                            setShow(!show);
                            setNameGroup(item);
                          }}
                        />
                      )}
                    </ContainImg>
                  </Item10>
                </GridContainer>
              ))}
            </>
          )}
        </ContainerTable>
        {/* </GridContainer> */}
        {show ? (
          <ModalDistribuidores
            show={show}
            setShow={setShow}
            datosGenerales={datosGenerales}
            formData={formData}
            clearData={clearData}
            nameGroup={{
              ...nameGroup,
              catalog_DistribuidorPadre: {
                name_DisPadre: showDistribuidores,
              },
            }}
          />
        ) : null}
      </MainContainer>
    </>
  );
}
