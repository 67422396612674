import React from 'react';
//styes
import { Container, Title } from '../PoliciesTerms/Terms.Styles';
//components
import { ReuseTerms } from '../../components/ReuseTerms/ReuseTerms';

export default function QuienesSomosP() {
  return (
    <Container>
      <Title>¿Quiénes somos?</Title>
      <ReuseTerms
        title='QUIÉNES SOMOS'
        term='Somos líderes en el suministro de refacciones y accesorios para la industria automotriz. Nuestro compromiso es ofrecer el catálogo más extenso de partes nuevas y originales, respaldadas por alianzas estratégicas con agencias automotrices de renombre. Esto nos permite proporcionarte productos de alta calidad a precios altamente competitivos.'
      />

      <ReuseTerms term='En BallenaMX, garantizamos que encontrarás la pieza exacta que tu vehículo necesita, respaldada por la garantía de fábrica. Además, ofrecemos envíos eficientes a tarifas justas y gestionamos cambios o devoluciones de manera conveniente para ti.' />

      <ReuseTerms term='Aceptamos diversos medios de pago ya que utilizamos el carrusel de pago de Mercado Pago. Te invitamos a explorar más sobre nuestro compromiso con la excelencia en el servicio.' />

      <ReuseTerms title='TÉRMINOS Y CONDICIONES' />

      <ReuseTerms
        title='AVISO LEGAL'
        term='Al acceder y utilizar nuestro sitio web (www.ballenamx.com), aceptas los términos y condiciones que detallamos a continuación. Esta aceptación se realiza de manera expresa y utilizando medios electrónicos, según lo dispuesto en el artículo 1803 del Código Civil Federal.'
      />

      <ReuseTerms term='Si no estás de acuerdo con alguno de estos términos y condiciones, te recomendamos abstenerse de utilizar www.ballenamx.com.' />

      <ReuseTerms term='El simple acceso al sitio web mencionado te otorga la condición de usuario, implicando la aceptación total de cada una de las condiciones generales y particulares incluidas en estos términos de uso. Nos reservamos el derecho de modificar los términos en cualquier momento, siendo tu responsabilidad estar al tanto de dichas modificaciones.' />

      <ReuseTerms term='Puedes imprimir y/o copiar información de BallenaMX exclusivamente para uso personal y no comercial. Queda expresamente prohibido cualquier uso diferente, como reimpresión, publicación, distribución, asignación, sublicencia, venta o reproducción electrónica, entre otros.' />

      <ReuseTerms term='La información, conceptos y opiniones publicadas en BallenaMX son propiedad de www.ballenamx.com. No nos hacemos responsables de las consecuencias derivadas del uso de la página, siendo tu responsabilidad el seguimiento de consejos, comentarios y demás información proporcionada.' />

      <ReuseTerms term='Nos reservamos el derecho de bloquear el acceso o eliminar información que, a nuestro juicio exclusivo, sea abusiva, difamatoria, fraudulenta, violatoria de derechos de propiedad intelectual, ofensiva o contravenga estos términos y condiciones.' />

      <ReuseTerms term='Si necesitas más información sobre algún tema específico, te recomendamos consultar directamente con nosotros o con un especialista en la materia.' />

      <ReuseTerms term='No está permitido interferir en las actividades de BallenaMX mediante el uso de dispositivos, robots, software u otros medios. Cualquier intromisión violatoria de las leyes de propiedad intelectual o de este contrato será sujeta a acciones legales y sanciones.' />

      <ReuseTerms
        title='BallenaMX - Refacciones Digitales S de RL de CV:'
        term='Av. Tláhuac 128, Santa Isabela Industrial, Iztapalapa CP 09820 CDMX'
      />

      <ReuseTerms
        title='DISPONIBILIDAD'
        term='Informamos a nuestros usuarios que la cantidad de piezas disponibles es estimada. Nos comprometemos a no poner a la venta más unidades de las disponibles en nuestros almacenes de manera intencional. Sin embargo, debido a posibles errores humanos o incidencias en sistemas informáticos, la cantidad ofrecida puede variar.'
      />
      <ReuseTerms term='Si algún producto no está disponible después de haberse realizado la compra, te informaremos por correo electrónico o teléfono sobre la cancelación total o parcial de tu pedido. Podrás elegir entre esperar la disponibilidad del producto o cancelar la compra.' />

      <ReuseTerms
        title='ENVÍOS'
        term='Realizamos envíos a todo el país. Los plazos de entrega pueden variar según la ubicación del destinatario y la disponibilidad del producto. Nos comprometemos a comunicarnos contigo en caso de retrasos significativos.'
      />

      <ReuseTerms term='No nos responsabilizamos por errores en la entrega cuando la dirección proporcionada por el usuario sea incorrecta o esté incompleta. Los costos de envío se detallan al momento de realizar la compra y corren a cargo del usuario, a menos que se ofrezca envío gratuito según condiciones específicas.' />

      <ReuseTerms
        title='CAMBIOS Y DEVOLUCIONES'
        term='Si necesitas cambiar o devolver un producto, por favor, contáctanos dentro de los 2 días hábiles posteriores a recibir tu pedido. Para productos defectuosos o dañados, BallenaMX cubrirá los costos asociados. En caso de devoluciones por otros motivos, los gastos de envío y cargos adicionales correrán por cuenta del usuario.'
      />

      <ReuseTerms
        title='GARANTÍA'
        term='Todos nuestros productos están respaldados por la garantía de fábrica del fabricante correspondiente. La duración de la garantía puede variar según el producto y el fabricante. Cubre defectos de fabricación, excluyendo mal uso, instalación incorrecta o desgaste normal.'
      />

      <ReuseTerms term='Para reclamaciones bajo garantía, ponte en contacto con nuestro servicio al cliente para recibir instrucciones sobre el proceso.' />

      <ReuseTerms
        title='POLÍTICA DE PRIVACIDAD'
        term='Respetamos tu privacidad. La información proporcionada se utiliza exclusivamente para gestionar la transacción y el envío. No compartimos tu información con terceros sin tu consentimiento. Para más detalles, consulta nuestra política de privacidad en www.ballenamx.com/privacidad.'
      />

      <ReuseTerms
        title='ATENCIÓN AL CLIENTE'
        term='Para consultas o información, nuestro servicio al cliente está disponible en info@ballenamx.com o +52 55 1234 5678, de lunes a viernes, de 9:00 a 18:00 horas.'
      />

      <ReuseTerms
        title='ÚLTIMA ACTUALIZACIÓN'
        term='Estos términos y condiciones fueron actualizados por última vez el 1 de febrero de 2024. Nos reservamos el derecho de modificarlos en cualquier momento sin previo aviso. Te recomendamos revisar periódicamente los términos y condiciones para estar al tanto de cambios.'
      />
    </Container>
  );
}
