/*  eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import TitleAlta from '../TilteAlta/TitleAlta';
import DatosGenerales from '../DatosGenerales/DatosGenerales';
import DireccionForm from '../DireccionForm/DireccionForm';
import Distribuidores from '../Distribuidores/Distribuidores';
import { GET_ALL_GROUPS, GET_INFO_GROUP } from '../../../../graphql/Query';
import { ADD_USER, DELE_USER } from '../../../../graphql/Mutation';
import Loader from '../../common/Loader';
import { GeneralContainer } from '../ExtraStyles.styles';
import Modal from '../Modal/Modal';

export default function AltaDistribuidor({ setClose }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState({
    title: '',
    body: '',
  });
  const [showDistribuidores, setShowDistribuidores] = useState('');
  const [newGroup, setNewGroup] = useState(true);
  const [groupDone, setGroupDone] = useState(false);
  const [listGroups, setListGroups] = useState([]);
  const [listBrands, setListBrands] = useState([]);
  const [listD2, setListD2] = useState([]);
  const [listD3, setListD3] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempData, setTempData] = useState({});
  const [missingFields, setMissingFields] = useState({
    nameGroup: true,
    nameUser: true,
    brands: true,
    street: true,
    numberOutside: true,
    cp: true,
    mail: true,
    phone: true,
    selectGroup: true,
    typeUser: true,
    idDistribuidor: true,
    selectd2: true,
    selectd3: true,
    selectedColony: true,
  });
  const [missingData, setMissingData] = useState({});
  const [validForm, setValidForm] = useState({});

  const [form, setForm] = useState({
    nameGroup: undefined,
    nameUser: undefined,
    brands: undefined,
    street: undefined,
    numberOutside: undefined,
    cp: undefined,
    mail: undefined,
    phone: undefined,
    selectGroup: undefined,
    typeUser: undefined,
    idDistribuidor: undefined,
    selectd2: '',
    selectd3: '',
    selectedColony: undefined,
    paisRegion: 'México',
    editableUser: false,
    calle: undefined,
    numeroExterior: undefined,
    numeroInterior: '',
    codigoPostal: undefined,
    correoElectronico: undefined,
    numeroCelular: undefined,
    selectedBrand: undefined,
    selectedIdBrand: undefined,
    selectedNameBrand: undefined,
    userStatus: 'activo',
    userName: undefined,
    selectedUserType: undefined,
    selectedD2: undefined,
    selectedD3: undefined,
    groupName: '',
    userd1: undefined,
    isD1: undefined,
    editingUser: false,
  });

  const [brands, setBrands] = useState([]);
  const [brandsNames, setBrandsNames] = useState([]);

  const resetForm = () => {
    setBrands([]);
    setForm({
      ...form,
      groupName: undefined,
      nameGroup: undefined,
      nameUser: undefined,
      brands: undefined,
      street: undefined,
      numberOutside: undefined,
      cp: undefined,
      mail: undefined,
      phone: undefined,
      selectGroup: undefined,
      typeUser: undefined,
      idDistribuidor: undefined,
      selectd2: '',
      selectd3: '',
      selectedColony: undefined,
      paisRegion: 'México',
      editableUser: false,
      calle: undefined,
      numeroExterior: undefined,
      numeroInterior: '',
      codigoPostal: undefined,
      correoElectronico: undefined,
      numeroCelular: undefined,
      selectedIdBrand: undefined,
      selectedNameBrand: undefined,
      userStatus: 'activo',
      userName: undefined,
      selectedUserType: undefined,
      selectedD2: undefined,
      selectedD3: undefined,
      undefined: undefined,
      selectedBrand: undefined,
      userd1: undefined,
      isD1: undefined,
      editingUser: false,
    });
  };

  // const validaForm = () => {
  //   if (!form.selectedUserType) {
  //     if (form.calle &&
  //       form.codigoPostal &&
  //       form.correoElectronico &&
  //       form.groupName &&
  //       form.numeroCelular &&
  //       form.numeroExterior &&
  //       form.numeroInterior &&
  //       form.paisRegion &&
  //       form.selectedColony &&
  //       form.selectedNameBrand &&
  //       form.userStatus) {
  //       return true
  //     } else {
  //       return false
  //     }
  //  else if (form.selectedUserType === 'D4') {
  //     if (
  //       form.groupId &&
  //       form.idDistribuidor &&
  //       form.selectedD2 &&
  //       form.selectedD3 &&
  //       form.selectedUserType &&
  //       form.userName &&

  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  // }

  useEffect(() => {
    const isValid = validaForm();
    const resultObject = convertArrayToObject(isValid.missingFields);
    setMissingData(resultObject);
  }, [form]);
  const validaForm = () => {
    const requiredFields = [
      'calle',
      'codigoPostal',
      'correoElectronico',
      'numeroCelular',
      'numeroExterior',
      'paisRegion',
      'selectedColony',
      'selectedBrand',
      'userStatus',
    ];

    const getMissingFields = (userType, isExistent) => {
      const requiredFieldsForType = requiredFields.concat(
        !userType && !isExistent ? ['groupName'] : [],
        isExistent && !userType
          ? ['userName', 'selectedUserType', 'nameGroup']
          : [],
        isExistent && userType === 'D2'
          ? ['userName', 'selectedUserType', 'nameGroup']
          : [],
        isExistent && userType === 'D3'
          ? ['userName', 'selectedUserType', 'nameGroup']
          : [],
        isExistent && userType === 'D4'
          ? ['userName', 'selectedUserType', 'idDistribuidor', 'nameGroup']
          : []
      );

      const missingFields = requiredFieldsForType.filter(
        (field) => !form[field]
      );

      return missingFields;
    };

    const isValidForUserType = (userType, isExistent) => {
      const missingFields = getMissingFields(userType, isExistent);

      return {
        isValid: missingFields.length === 0,
        missingFields: missingFields,
      };
    };

    return isValidForUserType(form.selectedUserType, !newGroup);
  };

  function convertArrayToObject(array) {
    const resultObject = {};
    array.forEach((item) => {
      resultObject[item] = true;
    });
    return resultObject;
  }

  const [createUser] = useMutation(ADD_USER, {
    onCompleted: ({ registerUser }) => {
      const parsed = JSON.parse(registerUser.response);
      if (parsed.response === 'El correo ya esta registrado') {
        setIsModalOpen(true);
        setModalText({
          title: 'Ups, correo duplicado',
          body: 'El correo ya esta registrado',
        });
        setLoading(false);
      } else if (
        parsed.response === 'El distribuidor ya se encuentra registrado'
      ) {
        setIsModalOpen(true);
        setModalText({
          title: 'Ups, distribuidor duplicado',
          body: 'El id distribuidor ya fue registrado, por favor ingresa otro. ',
        });
        setLoading(false);
      } else {
        setCreateClick(false);
        // setClearData(true);
        const parse = JSON.parse(registerUser.response);
        const idFather = parse.id_uac_d1;
        resetForm(idFather);
        reloadUsers(idFather);
        setLoading(false);
      }
      setLoading(false);
    },
    onError: (error) => {
      console.log({ error });
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
      setLoading(false);
    },
  });

  const saveUser = () => {
    setCreateClick(true);
    const isValid = validaForm();
    const resultObject = convertArrayToObject(isValid.missingFields);
    setMissingData(resultObject);
    setValidForm(isValid.isValid);

    if (isValid.isValid) {
      if (!form.selectedUserType) {
        const filterItems = listGroups.filter(
          (item) => item.group_name === form.groupName
        );
        if (filterItems.length > 0) {
          setIsModalOpen(true);
          setModalText({
            title: 'Ups, grupo duplicado',
            body: 'El grupo ya fue registrado, por favor ingresa otro. ',
          });
          setLoading(false);
          return;
        }
      }
      setLoading(true);
      createUser({
        variables: { data: JSON.stringify(form) },
      });
    }
  };
  const [createClick, setCreateClick] = useState(false);
  const [clearData, setClearData] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(windowWidth);

  // Función para manejar cambios en el tamaño de la ventana
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Efecto secundario para suscribirse a los cambios en el tamaño de la ventana
  useEffect(() => {
    // Agregar el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1440) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowWidth]);

  const [getGroups] = useLazyQuery(GET_ALL_GROUPS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getAllGroups }) {
      if (getAllGroups.statusCode === 200) {
        const info = JSON.parse(getAllGroups.response);
        setListGroups(info);
      }
    },
  });

  const [getDataGroups] = useLazyQuery(GET_INFO_GROUP, {
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoGroups }) {
      if (getInfoGroups.statusCode === 200) {
        const info = JSON.parse(getInfoGroups.response);
        const list = info.map((item) => {
          if (item.name_brand) {
            return {
              id: item.id_catalog_brands,
              name: item.name_brand,
            };
          } else {
            return {
              id: item.catalog_brand.id_catalog_brands,
              name: item.catalog_brand.name_brand,
            };
          }
        });
        setListBrands(list);
      }
    },
  });

  const [getUsersGroups] = useLazyQuery(GET_INFO_GROUP, {
    //Admin-login y nos lleva a add-user
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoGroups }) {
      if (getInfoGroups.statusCode === 200) {
        const info = JSON.parse(getInfoGroups.response);
        setShowDistribuidores(info[0]?.group_name);
        setAllUsers(info);
        const d2 = info.filter((item) => item.type_user === 3);
        const d3 = info.filter((item) => item.type_user === 4);
        setListD2(d2);
        setListD3(d3);
      }
    },
  });

  const [deleteUserMutation] = useMutation(DELE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted({ deleteUser }) {
      reloadUsers();
    },
  });

  const reloadUsers = (idFather) => {
    if (!idFather) {
      setListBrands([]);
      getDataGroups({
        variables: {
          id: currentGroup,
          type: 'brands',
        },
      });
    }
    getUsersGroups({
      variables: {
        id: idFather ? idFather.toString() : currentGroup,
        type: 'users',
      },
    });
    setLoading(false);
  };

  const deleteUser = (id) => {
    setLoading(true);
    deleteUserMutation({
      variables: {
        idUser: id.toString(),
      },
    });
  };

  const getTypeUser = (type) => {
    switch (type) {
      case 5:
        return 'D4';
      case 4:
        return 'D3';
      case 3:
        return 'D2';
      case 2:
        return 'D1';
      default:
        return '';
    }
  };

  const handleEditUser = (item, isD1) => {
    window.scrollTo(0, 0);
    // setTempData({ ...item, isD1: isD1 });
    const tempBrands = [];
    const brandsNames = [];
    item?.users_brands?.map((item) => {
      tempBrands.push(item.id_catalog_brands);
      brandsNames.push(item.catalog_brand.name_brand);
    });
    setBrands(tempBrands);
    setBrandsNames(brandsNames);
    setForm({
      ...form,
      nameGroup: item.type_user == 2 ? item.id_uac : item.catalog_user_id_d1,
      calle: item?.direccionesDistribuidors[0]?.street,
      numeroExterior: item?.direccionesDistribuidors[0]?.number,
      numeroInterior: item?.direccionesDistribuidors[0]?.number_inside,
      codigoPostal: item?.direccionesDistribuidors[0]?.zip,
      correoElectronico: item.mail,
      numeroCelular: item.phone,
      selectedColony: item?.direccionesDistribuidors[0]?.id_colony,
      selectValue: item.catalog_user_id_d1,
      groupId: 0,
      groupName: '',
      selectedUserType: getTypeUser(item.type_user),
      userName: item.name,
      userStatus: 'activo',
      selectedBrands: JSON.stringify(tempBrands)
        .replace('[', '')
        .replace(']', ''),
      selectedBrand: JSON.stringify(tempBrands)
        .replace('[', '')
        .replace(']', ''),
      idDistribuidor: item?.id_distribuidor,
      selectedDistribuidor: '',
      selectedD2: item.catalog_user_id_d2,
      selectedD3: item.catalog_user_id_d3,
      editableUser: true,
      nameUser: undefined,
      brands: undefined,
      street: undefined,
      numberOutside: undefined,
      cp: undefined,
      mail: undefined,
      phone: undefined,
      selectGroup: undefined,
      typeUser: undefined,
      selectd2: '',
      selectd3: '',
      selectedIdBrand: undefined,
      selectedNameBrand: undefined,
      undefined: undefined,
      isD1: isD1,
      editingUser: true,
    });
  };

  const getInfoGroup = (groupId, fatherId) => {
    setListBrands([]);
    setCurrentGroup(fatherId);
    getDataGroups({
      variables: {
        id: groupId,
        type: 'brands',
      },
    });
    getUsersGroups({
      variables: {
        id: fatherId,
        type: 'users',
      },
    });
  };

  useEffect(() => {
    getDataGroups({
      variables: {
        type: 'brands',
      },
    });
  }, []);

  useEffect(() => {
    if (groupDone) {
      getGroups();
      setShowDistribuidores('');
    } else {
      setShowDistribuidores('');
      getDataGroups({
        variables: {
          type: 'brands',
        },
      });
    }
  }, [groupDone]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    setTempData({});
    resetForm();
    setCreateClick(false);
    // setClearData(true)
  }, [groupDone]);

  return (
    <div style={{ width: '100%', padding: '1.5rem' }} onClick={setClose}>
      <Modal isOpen={isModalOpen} onClose={setIsModalOpen}>
        <h2
          style={{
            fontSize: '32px',
            fontFamily: 'Mulish',
            color: '#0D3564',
            fontWeight: '800',
          }}
        >
          {modalText.title}
        </h2>
        <p
          style={{
            color: '#14181A',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '18px',
          }}
        >
          {modalText.body}
        </p>
      </Modal>

      <Loader open={loading} />
      <TitleAlta
        newGroup={newGroup}
        groupDone={groupDone}
        setNewGroup={setNewGroup}
        setGroupDone={setGroupDone}
      />
      <GeneralContainer>
        <div style={{ width: '100%' }}>
          <DatosGenerales
            form={form}
            setForm={setForm}
            clearData={clearData}
            missingData={missingData}
            setClearData={setClearData}
            newGroup={newGroup}
            groupDone={groupDone}
            listGroups={listGroups}
            getInfoGroup={getInfoGroup}
            listBrands={listBrands}
            listD2={listD2}
            listD3={listD3}
            missingFields={missingFields}
            createClick={createClick}
            setMissingFields={setMissingFields}
            tempData={tempData}
            mobile={mobile}
            brandsIds={brands}
            brandsNames={brandsNames}
          />
        </div>
        <div style={{ width: '100%' }}>
          <DireccionForm
            form={form}
            setForm={setForm}
            missingData={missingData}
            setClearData={setClearData}
            reloadUsers={reloadUsers}
            setShowDistribuidores={setShowDistribuidores}
            missingFields={missingFields}
            createClick={createClick}
            setCreateClick={setCreateClick}
            setMissingFields={setMissingFields}
            setLoading={setLoading}
            tempData={tempData}
            clearData={clearData}
            mobile={mobile}
            saveUser={saveUser}
          />
        </div>
      </GeneralContainer>
      {showDistribuidores !== '' && (
        <Distribuidores
          allUsers={allUsers}
          deleteUser={deleteUser}
          showDistribuidores={showDistribuidores}
          handleEditUser={handleEditUser}
        />
      )}
    </div>
  );
}
