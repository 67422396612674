/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Flex } from '../styles/common.styles';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import { Finder } from '../components/Store/Finder/Finder';
// import MarketOptions from '../components/MainPage/MarketOptions';
import FinderResults from '../components/MainPage/FinderResults';
import NavLog from '../components/common/NavbarMktp/NavLog';
import { useLazyQuery } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_FILTER } from '../../graphql/Query';
import NoResults from '../components/MainPage/Modals/NoResults';

const Store = () => {
  const [resultData, setResultData] = useState(undefined);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  window.addEventListener('beforeunload', () => {
    sessionStorage.removeItem('Result');
    sessionStorage.removeItem('Brand');
    sessionStorage.removeItem('Ctry');
    sessionStorage.removeItem('idPart');
  });

    useQuery(GET_PRODUCTS_BY_FILTER, {
      variables: {
        categoryId: '',
        brandId: '',
        part: '',
        pageNumber: currentPage,
        pageSize: 12,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getProductsByFilter }) => {
        setResultData(getProductsByFilter.data);
      },
    });

  useEffect(() => {
    let resultDataJson = sessionStorage.getItem('Result');
    let pages = sessionStorage.getItem('Pages');
    if (resultDataJson) {
      let resultData2 = JSON.parse(resultDataJson);
      setResultData(resultData2);
      setPages(pages);
    }
  }, []);
  const email = sessionStorage.getItem('auxEmail');

  const [searchByFilter] = useLazyQuery(GET_PRODUCTS_BY_FILTER, {
    fetchPolicy: 'no-cache',
    onCompleted: (getProductsByFilter) => {
      setResultData(getProductsByFilter.getProductsByFilter.data);
    },
  });

  const handlePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    searchByFilter({
      variables: {
        categoryId: '',
        brandId: '',
        part: '',
        pageNumber: selectedPage,
        pageSize: 12,
      },
    });
  };

  const reloadData = (data) => {
    setResultData(data);
  };

  useEffect(() => {
    resultData?.length === 0 ? setOpenModal(true) : null;
  }, [resultData]);

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Nav store />
      <NoResults open={openModal} setClose={setOpenModal} />
      <Flex direction='column' width='100%' gap='24px'>
        <Finder
          reloadData={reloadData}
          setPages={setPages}
          page={currentPage}
        />
        {resultData && (
          <FinderResults
            resultData={resultData}
            pages={pages}
            setPages={setPages}
            handlePage={handlePage}
            setTotalResults={setTotalResults}
          />
        ) 
        // : (
        //   <MarketOptions
        //     reloadData={reloadData}
        //     setPages={setPages}
        //     setTotalResults={setTotalResults}
        //     page={currentPage}
        //   />
        // )
        }
      </Flex>
      <Footer />
    </>
  );
};

export default Store;
