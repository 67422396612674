import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { url } from '../../../api';
//styles
import {
  Overlay,
  MainContainer,
  CloseContainer,
  ButtonCargar,
  ButtonSelect,
  CargaArchivosContainer,
  DescriptionText,
  InputModal,
  MainTitle,
  SelectContainer,
  Subtitle,
  TextCargar,
  TextCargarWarning,
  UploadImg,
  UploadImgeHeader,
  LabelSelect,
  DimensionsContainer,
  ButtonContainer,
  Xtext,
} from './ModalIngresarDatosStyles';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE } from '../../../../graphql/Mutation';
import upload from '../../../assets/upload.svg';
import fileImg from '../../../assets/archivo.svg';
import { Flex } from '../../../styles/common.styles';
import {
  InputModalSuffix,
  UnstyledInput,
} from '../ModalEditar/ModalEditarAdminMktstyles';

const close = `${url}CloseButton.png`;
export default function ModalIngresarDatos({
  showModal,
  setShowModal,
  handleEdit,
  setLoading,
}) {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [file, setFile] = useState(null);
  const [valid, setValid] = useState(true);
  const [nameSelectedFile, setNameSelectedFile] = useState('');

  const onDrop = async (acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setNameSelectedFile(acceptedFiles[0].name);
  };

  const saveFile = async () => {
    if (
      formEditData.height !== '' &&
      formEditData.length !== '' &&
      formEditData.width !== '' &&
      formEditData.weight !== ''
    ) {
      try {
        let url = '';
        if (file && nameSelectedFile !== '') {
          const { data } = await uploadFile({
            variables: {
              file,
              name: `${showModal?.repair || 'repair'}-${
                showModal?.id_autopart || 'id_autopart'
              }-${showModal?.id_part || 'id_part'}`,
            },
          });
          url = data?.singleUpload;
        }

        handleEdit({
          ...formEditData,
          image: url,
          name_image: nameSelectedFile,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      setValid(false);
    }
    setLoading(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    multiple: false, // Disable multiple file selection   // Allow only one file
  });

  const [formEditData, setFormEditData] = useState({
    name: showModal?.repair || '',
    part: showModal?.id_autopart || '',
    partId: showModal?.id_part || '',
    description: showModal?.description || '',
    averageCost: showModal?.average_price || '',
    marketPlacePrice: showModal?.final_price || '',
    height: showModal?.height || '',
    width: showModal?.width || '',
    length: showModal['length'] || '',
    weight: showModal?.weight || '',
    image: showModal?.image || '',
    postInMarketPlace: true,
  });

  useEffect(() => {
    return () => {
      // limpieza del efecto
    };
  }, [formEditData]);

  return (
    <Overlay>
      <MainContainer>
        <CloseContainer>
          <img onClick={() => setShowModal(false)} src={close} alt='x' />
        </CloseContainer>
        <Flex
          direction='row'
          gap='8px'
          align='center'
          width='100%'
          mobile='flex-direction: column;'
        >
          <img alt='' src={upload} style={{ width: '48px' }} />
          <MainTitle style={{ width: '100%', textAlign: 'center' }}>
            Información complementaria
          </MainTitle>
        </Flex>
        <DescriptionText>
          Necesitamos que nos apoyes a completar la siguiente información para
          poder asociar tu producto en el sistema.
        </DescriptionText>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Información general</Subtitle>

          <SelectContainer>
            <LabelSelect>Nombre del producto</LabelSelect>
            <InputModal
              onChange={(e) =>
                setFormEditData({ ...formEditData, name: e.target.value })
              }
              value={formEditData.name}
              placeholder='Colocar nombre del producto'
            />
          </SelectContainer>

          <SelectContainer>
            <LabelSelect>Descripcións del producto</LabelSelect>
            <InputModal
              onChange={(e) =>
                setFormEditData({
                  ...formEditData,
                  description: e.target.value,
                })
              }
              value={formEditData.description}
              placeholder='Colocar descripción del producto'
            />
          </SelectContainer>
        </Flex>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Volumetría</Subtitle>

          <DimensionsContainer>
            <SelectContainer>
              <LabelSelect>Alto</LabelSelect>
              <InputModalSuffix
                validIn={formEditData.height == '' && valid == false}
              >
                <UnstyledInput
                  onChange={(e) => {
                    const nuevoValor = e.target.value.replace(/[^0-9.]/g, '');
                    setFormEditData({
                      ...formEditData,
                      height: nuevoValor,
                    });
                  }}
                  value={formEditData?.height}
                />
                <div>cm</div>
              </InputModalSuffix>
            </SelectContainer>
            <Xtext>X</Xtext>
            <SelectContainer>
              <LabelSelect>Ancho</LabelSelect>
              <InputModalSuffix
                validIn={formEditData.width == '' && valid == false}
              >
                <UnstyledInput
                  onChange={(e) => {
                    const nuevoValor = e.target.value.replace(/[^0-9.]/g, '');
                    setFormEditData({
                      ...formEditData,
                      width: nuevoValor,
                    });
                  }}
                  value={formEditData?.width}
                />
                <div>cm</div>
              </InputModalSuffix>
            </SelectContainer>
          </DimensionsContainer>
          <DimensionsContainer>
            <SelectContainer>
              <LabelSelect>Largo</LabelSelect>
              <InputModalSuffix
                validIn={formEditData.length == '' && valid == false}
              >
                <UnstyledInput
                  onChange={(e) => {
                    const nuevoValor = e.target.value.replace(/[^0-9.]/g, '');
                    setFormEditData({
                      ...formEditData,
                      length: nuevoValor,
                    });
                  }}
                  value={formEditData?.length}
                />
                <div>cm</div>
              </InputModalSuffix>
            </SelectContainer>
            <SelectContainer>
              <LabelSelect>Peso</LabelSelect>
              <InputModalSuffix
                style={{ maxWidth: '100%' }}
                validIn={formEditData.weight == '' && valid == false}
              >
                <UnstyledInput
                  style={{ width: '80%' }}
                  onChange={(e) => {
                    const nuevoValor = e.target.value.replace(/[^0-9.]/g, '');

                    setFormEditData({
                      ...formEditData,
                      weight: nuevoValor,
                    });
                  }}
                  value={formEditData?.weight}
                />
                <div>kg</div>
              </InputModalSuffix>
            </SelectContainer>
          </DimensionsContainer>
        </Flex>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Subir imagen del producto</Subtitle>
          {nameSelectedFile !== '' ? (
            <CargaArchivosContainer>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <UploadImg
                    src={fileImg}
                    style={{
                      marginTop: '-5px',
                      marginRight: '10px',
                    }}
                  />
                  {nameSelectedFile}
                </div>
                <div>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => setNameSelectedFile('')}
                    src={close}
                    alt='x'
                  />
                </div>
              </div>
            </CargaArchivosContainer>
          ) : (
            <CargaArchivosContainer>
              <UploadImg src={upload} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <TextCargar>Selecciona el archivo</TextCargar>
                <TextCargarWarning>
                  Formato .jpg, .png <br />
                  Peso de 10MB
                </TextCargarWarning>
              </div>

              <div>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <ButtonSelect as='label' htmlFor='fileInput'>
                    Seleccionar
                  </ButtonSelect>
                </div>
              </div>
            </CargaArchivosContainer>
          )}
        </Flex>

        <ButtonContainer>
          <ButtonSelect onClick={() => setShowModal(false)}>
            Cancelar
          </ButtonSelect>
          <ButtonCargar onClick={() => saveFile()}>Cargar</ButtonCargar>
        </ButtonContainer>
      </MainContainer>
    </Overlay>
  );
}
