/* eslint-disable */
import React from 'react';

import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
} from '../../../styles/common.styles';

import { url } from '../../../api';

const warning = `${url}warnigSign.svg`;
const SuccessModal = ({ open, setOpen, reloadCurrentList, setLoading}) => {

  const handleClick = () => {
    reloadCurrentList();
    setOpen(false);
    setLoading(true)
  };
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Flex gap='16px'>
              <Text size='32px' weight='800' color='#0D3564'>
                Publicación exitosa
              </Text>
            </Flex>
            <Text family='Inter' size='16px' weight='400' color='#14181A'>
              El producto se ha logrado publicar de manera correcta.
            </Text>
            <Flex align='center' gap='24px' justify='center'>
              <Button width='194px' onClick={handleClick}>Entendido</Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default SuccessModal;
