import React from 'react';
//styles
import {
  MainContainer,
  AmountBold,
  AmountEnvio,
  AmountGray,
  CantidadText,
  DescriptionText,
  GridContaier,
  GridTextTile,
  PrecioText,
  SubotalBold,
  SubtotalText,
  Title,
  SubtotalGrayContainer,
  InnerContaier,
} from './PaqueteriaDrop.styles';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../../../helpers/shopCart';
export default function PaqueteriaDrop({
  item,
  costoEnvio,
  countItems,
  subTotal,
  nopad,
  nopad2,
}) {
  return (
    <MainContainer
      style={{ padding: nopad ? '0 0 32px' : nopad2 ? '0 32px 32px' : null }}
    >
      <Title>Productos</Title>
      <InnerContaier>
        <GridContaier>
          <GridTextTile>Descripción</GridTextTile>
          <GridTextTile>Cantidad</GridTextTile>
          <GridTextTile>No. Parte</GridTextTile>
          <GridTextTile>Precio</GridTextTile>
          {item.map((item) => (
            <>
              <DescriptionText>{item.name}</DescriptionText>
              <CantidadText style={{ width: '100%', textAlign: 'center' }}>
                {item.qty}
              </CantidadText>
              <CantidadText>{item.partNumber}</CantidadText>
              <PrecioText>
                ${formatNumberWithTwoDecimals(stringToNumber(item.price))} MXN{' '}
              </PrecioText>
            </>
          ))}
        </GridContaier>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '4px',
          }}
        >
          <SubtotalGrayContainer style={{ justifyContent: 'flex-end' }}>
            <SubtotalText style={{ margin: '0' }}>
              Subtotal ({countItems} productos):{' '}
            </SubtotalText>
            <AmountGray style={{ margin: '0' }}>
              ${formatNumberWithTwoDecimals(subTotal)} MXN{' '}
            </AmountGray>
          </SubtotalGrayContainer>

          <SubtotalGrayContainer style={{ justifyContent: 'flex-end' }}>
            <SubtotalText style={{ margin: '0' }}>Envío:</SubtotalText>
            <AmountEnvio style={{ margin: '0' }}>
            {costoEnvio !== 0 ? costoEnvio : 'No has seleccionado'}
            </AmountEnvio>
          </SubtotalGrayContainer>

          <SubtotalGrayContainer>
            <SubotalBold style={{ margin: '0' }}>Subtotal: </SubotalBold>
            <AmountBold style={{ margin: '0' }}>
              ${formatNumberWithTwoDecimals(subTotal)} MXN
            </AmountBold>
          </SubtotalGrayContainer>
        </div>
      </InnerContaier>
    </MainContainer>
  );
}
