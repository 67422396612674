/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, StyledInput } from '../../styles/common.styles';
// import DashboardTable from '../Dashboard/DashboardTable';
import ModalUpload from '../Dashboard/ModalUpload/ModalUpload';
import { useState } from 'react';
import { url } from '../../api';
import AdminMktTable from './AdminMktTable';
import {
  GET_DATA_FOR_FILTER,
  GET_EXCEL_LIST_MARKETPLACE,
  GET_LIST_MARKETPLACE,
} from '../../../graphql/Query';
import { useLazyQuery } from '@apollo/client';
import Loader from '../../components/common/Loader';
const DashboardLogo = `${url}Dashboard.svg`;
const ubicacion = `${url}ubicacion.svg`;
const DownArrow = `${url}downArrow.svg`;
const MarketPlace = `${url}MarketPlace.svg`;

const AdminMtk = ({ isAdminDashboard, setClose }) => {
  const [itemsList, setItemsList] = useState({
    countAll: 0,
    countNoPublished: 0,
    countPublished: 0,
    countPagination: 0,
    items: [],
    urlExcel: '',
    totalPages: 0,
  });

  const [infoFilters, setInfoFilters] = useState({
    dates: [],
    groups: [],
    brands: [],
  });

  const [showModalDelete, setShowModalDelete] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  // const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalUploadTest, setOpenModalUploadTest] = useState(false);
  const [publish, setPublish] = useState(true);
  const [showModalEmpySearch, setShowModalEmpySearch] = useState(false);
  const [urlExcel, setUrlExcel] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [valueFilters, setValueFilters] = useState({
    date: '',
    idUac: '',
    idManufacture: '',
    idPart: '',
  });

  const handleExcelDownload = () => {
    if (!loadingExcel) {
      const link = document.createElement('a');
      link.href = urlExcel;
      link.download = 'Excel_Autopartes.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const [getListItems] = useLazyQuery(GET_LIST_MARKETPLACE, {
    fetchPolicy: 'no-cache',
    onCompleted({ listItemsMarketplace }) {
      // console.log({ listItemsMarketplace })
      if (listItemsMarketplace.statusCode === 200) {
        /**Fin Excel actualizado */
        const items = JSON.parse(listItemsMarketplace.items);
        setItemsList({
          ...itemsList,
          countAll: listItemsMarketplace.countAll,
          countNoPublished: listItemsMarketplace.countNoPublished,
          countPublished: listItemsMarketplace.countPublished,
          items: items,
          countPagination:
            listItemsMarketplace.countPagination === 0
              ? 1
              : listItemsMarketplace.countPagination,
          urlExcel: url,
          totalPages: listItemsMarketplace.totalPagination,
        });
        if (items.length === 0) {
          setShowModalEmpySearch(true);
        }
        setLoading(false);
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const [getExcelListItems] = useLazyQuery(GET_EXCEL_LIST_MARKETPLACE, {
    fetchPolicy: 'no-cache',
    onCompleted({ listExcelItemsMarketplace }) {
      if (listExcelItemsMarketplace.statusCode === 200) {
        /**Excel actualizado */
        // Convertir el base64 a un blob y crear un enlace de descarga
        const binaryData = atob(listExcelItemsMarketplace.base64Excel);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);

        setUrlExcel(url);
        /**Fin Excel actualizado */
      }
      setLoadingExcel(false);
    },
    onError: (error) => {
      setLoadingExcel(false);
    },
  });

  useEffect(() => {
    console.log(itemsList);
    return () => {
      // limpieza del efecto
    };
  }, [itemsList]);

  const [getInfoForFilters] = useLazyQuery(GET_DATA_FOR_FILTER, {
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoForFilters }) {
      // console.log({ getInfoForFilters })
      if (getInfoForFilters.statusCode === 200) {
        setInfoFilters({
          dates: JSON.parse(getInfoForFilters.dates),
          groups: JSON.parse(getInfoForFilters.groups),
          brands: JSON.parse(getInfoForFilters.brands),
        });
      }
    },
  });

  const handleFilters = (e) => {
    setValueFilters({
      ...valueFilters,
      [e.target.name]: e.target.value,
    });
  };
  const search = () => {
    setCurrentPage(1);
    setLoading(true);
    setLoadingExcel(true);
    getListItems({
      variables: {
        page: 1,
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        idPart: valueFilters.idPart,
      },
    });
    getExcelListItems({
      variables: {
        page: 1,
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        idPart: valueFilters.idPart,
      },
    });
  };

  const reloadCurrentList = () => {
    getListItems({
      variables: {
        page: currentPage,
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        idPart: valueFilters.idPart,
      },
    });
    getExcelListItems({
      variables: {
        page: currentPage,
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        idPart: valueFilters.idPart,
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    // console.log({ newPage });
    setCurrentPage(newPage);
    setLoading(true);
    setLoadingExcel(true);
    getListItems({
      variables: {
        page: newPage,
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        idPart: valueFilters.idPart,
      },
    });
  };

  const changePublished = (value) => {
    setCurrentPage(1);
    setPublish(value);
    setLoading(true);
    setLoadingExcel(true);
    getListItems({
      variables: {
        page: 1,
        inMarketplace: value,
        date: '',
        idUac: '',
        idManufacture: '',
        idPart: '',
      },
    });
    getExcelListItems({
      variables: {
        page: 1,
        inMarketplace: value,
        date: '',
        idUac: '',
        idManufacture: '',
        idPart: '',
      },
    });
  };
  const getListItemsMarketplace = () => {
    setLoading(true);
    setLoadingExcel(true);
    setValueFilters({
      date: '',
      idUac: '',
      idManufacture: '',
      idPart: '',
    });
    getListItems({
      variables: {
        page: 1,
        inMarketplace: publish,
        date: '',
        idUac: '',
        idManufacture: '',
      },
    });
    getExcelListItems({
      variables: {
        page: 1,
        inMarketplace: publish,
        date: '',
        idUac: '',
        idManufacture: '',
      },
    });
  };

  const handleCleanFilter = () => {
    setLoading(true);
    getListItemsMarketplace();
  };

  useEffect(() => {
    setLoading(true);
    setLoadingExcel(true);
    getListItemsMarketplace();
    getInfoForFilters();
  }, []);

  return (
    <>
      <Loader open={loading} />
      <ModalUpload
        open={openModalUploadTest}
        setOpen={setOpenModalUploadTest}
        reloadCurrentList={reloadCurrentList}
      />

      <MainContaier onClick={setClose}>
        <TopContainer>
          {isAdminDashboard ? (
            <TitleContain>
              <img style={{ width: '48px' }} src={DashboardLogo} />
              Dashboard
            </TitleContain>
          ) : (
            <TitleContain>
              <img style={{ width: '48px' }} src={MarketPlace} />
              Admin Market Place
            </TitleContain>
          )}
          {!isAdminDashboard && (
            <Button
              mobile='width:100%;'
              onClick={() => setOpenModalUploadTest(true)}
            >
              Cargar información
            </Button>
          )}
        </TopContainer>
        <ContainerInputs isAdminDashboard={isAdminDashboard}>
          <ContainerSubtitle style={{ display: 'flex', alignItems: 'center' }}>
            <img alt='' src={ubicacion} />
            <SubtitleText style={{ margin: '0' }}>
              Piezas en el listado
            </SubtitleText>
          </ContainerSubtitle>
          <FlexRow>
            <SelectContainer>
              <LabelSelect>Total del listado:</LabelSelect>
              <InputMkt value={itemsList.countAll} disabled />
            </SelectContainer>
            <SelectContainer>
              <LabelSelect>Total de piezas no publicadas:</LabelSelect>
              <InputMkt value={itemsList.countNoPublished} disabled />
            </SelectContainer>
            <SelectContainer>
              <LabelSelect>
                Total de piezas publicadas en el Marketplace:{' '}
              </LabelSelect>
              <InputMkt value={itemsList.countPublished} disabled />
            </SelectContainer>
          </FlexRow>
        </ContainerInputs>
        <ContainerButtonPublicaciones isAdminDashboard={isAdminDashboard}>
          <SelectContainer style={{ width: '300px' }}>
            <LabelSelect style={{ margin: '0 4px' }}>Piezas</LabelSelect>
            <InnerContainerButtons>
              <ButtonPublicar
                publish={publish}
                onClick={() => changePublished(true)}
              >
                Publicadas
              </ButtonPublicar>
              <ButtonNoPublicar
                publish={publish}
                onClick={() => changePublished(false)}
              >
                No publicadas
              </ButtonNoPublicar>
            </InnerContainerButtons>
          </SelectContainer>
        </ContainerButtonPublicaciones>
        <FlexRow>
          <SelectContainer>
            <LabelSelect>Fecha</LabelSelect>
            {/* <InputDate type='date' /> */}
            <SelectField
              name='date'
              value={valueFilters.date}
              onChange={handleFilters}
            >
              <option selected disabled value=''>
                Elige una opción
              </option>
              {infoFilters.dates.map((date) => (
                <option key={date.uploadDate} value={date.uploadDate}>
                {date.uploadDate}
                </option>
              ))}
            </SelectField>
          </SelectContainer>
          <SelectContainer>
            <LabelSelect>Grupo distribuidor</LabelSelect>
            <SelectField
              name='idUac'
              value={valueFilters.idUac}
              onChange={handleFilters}
            >
              <option selected disabled value=''>
                Elige una opción
              </option>
              {infoFilters.groups.map((group) => (
                <option
                  key={group.id_distribuidor}
                  value={group.id_distribuidor}
                >
                  {group.name}
                </option>
              ))}
            </SelectField>
          </SelectContainer>
          <SelectContainer>
            <LabelSelect>Marca</LabelSelect>
            <SelectField
              name='idManufacture'
              value={valueFilters.idManufacture}
              onChange={handleFilters}
            >
              <option selected disabled value=''>
                Elige una opción
              </option>
              {infoFilters.brands.map((brand) => (
                <option key={brand.id_manufacture} value={brand.id_manufacture}>
                  {brand['catalog_brands.name_brand']}
                </option>
              ))}
            </SelectField>
          </SelectContainer>
          <SelectContainer>
            <LabelSelect>ID de pieza</LabelSelect>
            <StyledInput
              name='idPart'
              value={valueFilters.idPart}
              onChange={handleFilters}
              width='100%'
              placeholder='Ingresa ID de Pieza'
              mobile='min-width:100%;'
            />
          </SelectContainer>
          <Button
            onClick={search}
            width='100%'
            mobile='width:100%; max-width:100%;'
            maxwidth='194px'
            style={{ marginBottom: '4px' }}
          >
            Buscar
          </Button>
          <Button
            onClick={handleCleanFilter}
            maxwidth='194px'
            secondary
            mobile='width:100%; max-width:100%;'
            width='100%'
            style={{ marginBottom: '4px' }}
          >
            Limpiar filtro
          </Button>
        </FlexRow>

        <AdminMktTable
          setLoading={setLoading}
          setLoadingExcel={setLoadingExcel}
          publish={publish}
          valueFilters={valueFilters}
          items={itemsList.items}
          totalItemsPublished={itemsList.countPagination}
          totalItemsNoPublished={itemsList.countPagination}
          handleChangePage={handleChangePage}
          currentPage={currentPage}
          reloadCurrentList={reloadCurrentList}
          showModalEmpySearch={showModalEmpySearch}
          setShowModalEmpySearch={setShowModalEmpySearch}
          isAdminDashboard={isAdminDashboard}
          showModalDelete={showModalDelete}
          setShowModalDelete={setShowModalDelete}
          totalPages={itemsList.totalPages}
          // setLoading={setLoading}
        />
        {/* <FlexRow
        // isAdminDashboard={isAdminDashboard}
        >
          <Button
            width='194px'
            secondary
            mobile='width:100%;'
            onClick={() => setShowModalDelete(true)}
          >
            Borrar
          </Button>
        </FlexRow> */}
        <FlexRow
        // isAdminDashboard={isAdminDashboard}
        >
          <div style={{ width: '100%' }}>
            <ButtonExcel
              width='194px'
              secondary
              onClick={handleExcelDownload}
              disabled={loadingExcel}
            >
              <LoaderSmall active={loadingExcel} />
              Exportar tabla
            </ButtonExcel>
          </div>
        </FlexRow>
      </MainContaier>
    </>
  );
};

export default AdminMtk;

const MainContaier = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 24px 80px 24px;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 1023px) {
  }
`;

const TitleContain = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  color: #0d3564;
  font-family: Mulish;
  font-size: 40px;
  font-weight: 800;
  @media (max-width: 1439px) {
    font-size: 32px;
  }
`;

const IngresaRow = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  @media (max-width: 1439px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
`;

const RowsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > div:nth-child(even) {
    background: red;
    color: white;
  }
  & > div:nth-child(odd) {
    background: blue;
    color: white;
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: ${(props) => (props.isAdminDashboard ? 'none' : 'flex')};
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  gap: 24px;
  @media (max-width: 1439px) {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    gap: 16px;
  }
`;

const ContainerInputs = styled.div`
  display: ${(props) => (props.isAdminDashboard ? 'none' : 'flex')};
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--neutral-white, #fff);

  /* Shadow_cards */
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
`;

const ContainerSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SubtitleText = styled.p`
  color: var(--primarydeep, #0d3564);
  text-align: center;

  /* DESKTOP/H5 */
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
`;

const InputMkt = styled.input`
  display: flex;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--neutral-storm, #8299a3);
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-very-light-gray, #f4f4f4);
  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  @media (max-width: 1439px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ContainerButtonPublicaciones = styled.div`
  display: ${(props) => (props.isAdminDashboard ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;
const LabelSelect = styled.p`
  color: var(--primary-darkblue, #061222);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  margin: 0;
`;

const InnerContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonPublicar = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
  background: ${(props) => (props.publish ? '#41B770' : '#DCDEDE')};
  color: ${(props) => (props.publish ? '#FFF' : '#8299A3')};

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.publish ? '700' : '400')};

  line-height: 120%; /* 19.2px */
  cursor: pointer;
`;

const ButtonNoPublicar = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 0px 12px 12px 0px;
  background: ${(props) => (props.publish ? '#DCDEDE' : '#D22121')};
  color: ${(props) => (props.publish ? '#8299A3' : '#FFF')};

  /* COMMON/Body_Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.publish ? '400' : '700')};

  line-height: 120%; /* 19.2px */
  cursor: pointer;
`;
const InputDate = styled.input`
  display: flex;
  height: 48px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
`;

const SelectField = styled.select`
  display: flex;
  height: 48px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
  color: var(--neutral-storm, #8299a3);

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  background-image: url(${DownArrow});
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 48px;
  appearance: none;
`;

const LoaderSmall = styled.div`
  display: ${(props) => (props.active ? 'grid' : 'none')};
  position: absolute;
  left: 5px;
  width: 30px;
  margin-right: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgba(13, 53, 100, 0.5) 30%,
        rgba(0, 0, 0, 0) 0 70%,
        rgba(13, 53, 100, 1) 0
      )
      50%/8% 100%,
    linear-gradient(
        90deg,
        rgba(13, 53, 100, 0.25) 30%,
        rgba(0, 0, 0, 0) 0 70%,
        rgba(13, 53, 100, 0.75) 0
      )
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);

  &::before,
  &::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  &::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }

  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const ButtonExcel = styled.button`
  position: relative;
  outline: none;
  border: ${(props) => (props.secondary ? '1px solid #0D3564' : 'none')};
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => (props.secondary ? '#ffffff' : '#0d3564')};
  color: ${(props) => (props.secondary ? '#0d3564' : '#ffffff')};
  width: ${(props) => props.width || 'fit-content'};
  min-width: ${(props) => props.minwidth || 'fit-content'};
  max-width: ${(props) => props.maxwidth || '100%'};
  height: 40px;
  ${(props) =>
    props.disabled
      ? 'background:#DCDEDE; color: rgba(130, 153, 163, 1);'
      : null}
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;
