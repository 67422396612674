/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  StyledContainer,
  StyledHead,
  StyledCont,
  BottomStyled,
  StyledInput,
  UserResponse,
  BotResponse,
  Styledicon,
} from './Chatbot.styles';
import Sally from './Assets/AVATAR.svg';
import Icon from './Assets/Icon.svg';

const Chatbot = () => {
  const scrollableDivRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [idUser, setIdUser] = useState('');
  const [allMessages, setAllMessages] = useState([]);
  const [urlUmi, setUrlUmi] = useState(
    'https://api.rocketbot.therocketcode.com.mx/chat-ballena'
  );
  const [errorMessage, setErrorMessage] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  const [message, setMessage] = useState('hola');

  // const sendMessage = (userMessage) => {
  //   fetchBot(userMessage)
  // };

  const dataUser = {
    userMessage: message,
    idSession: idUser,
  };

  const dataPost = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataUser),
  };

  const botMessage = async () => {
    setIsWriting(true);
    try {
      const response = await fetch(urlUmi, dataPost);
      const data = await response.json();
      // console.log('data', data.response);
      const day = new Date();
      let hour = day.getHours();
      const minutes = day.getMinutes();
      let momento = 'am';
      if (hour >= 12) {
        momento = 'pm';
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      const timeMessage = `${String(hour)}:${String(minutes)} ${momento}`;

      setAllMessages((allData) => [
        ...allData,
        {
          message: data.response.message,
          isUser: false,
          time: timeMessage,
          type: 'bot',
        },
      ]);
    } catch (err) {
      console.log('Error: ', err);
      setErrorMessage(true);
    } finally {
      setIsWriting(false);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    const regex = /^.+/;
    const removeSpaces = message.trimStart();
    if (regex.test(message)) {
      const day = new Date();
      let hour = day.getHours();
      const minutes = day.getMinutes();
      let momento = 'am';
      if (hour >= 12) {
        momento = 'pm';
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      const timeMessage = `${String(hour)}:${String(minutes)} ${momento}`;
      setAllMessages((data) => [
        ...data,
        {
          message: removeSpaces,
          isUser: true,
          time: timeMessage,
          type: 'user',
        },
      ]);
    }
    setMessage('');
    botMessage();
  };

  //   useEffect para desplazar a nuevo mensaje en cuanto se actualice el mensaje
  useEffect(() => {
    const scrollToBottom = () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [allMessages, isWriting, open]); // Scroll hasta abajo cuando esté escribiendo, nuevo mensaje (de acualquier parte) y cuando se abra

  useEffect(() => {
    const randomId = uuidv4();
    setIdUser(randomId);
  }, []);

  const [firstMessage, setFirstMessage] = useState(false);

  useEffect(() => {
    if (allMessages.length === 0) {
      setFirstMessage(true);
    } else {
      setFirstMessage(false);
    }
  }, [allMessages]);

  useEffect(() => {
    if (firstMessage && idUser) {
      const fakeEvent = {
        preventDefault: () => {}, // Simula la función preventDefault
      };
      handleSendMessage(fakeEvent);
    }
  }, [firstMessage, idUser]);

  return (
    <>
      <StyledContainer style={{ display: open ? 'flex' : 'none' }}>
        <StyledHead>
          <img alt='' src={Sally} />
          Sally
          <div
            style={{
              position: 'absolute',
              top: '24px',
              right: '24px',
              gap: '12px',
              fontSize: '16px',
              display: 'flex',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setOpen(false)}
            >
              ✕
            </div>
          </div>
        </StyledHead>
        <StyledCont ref={scrollableDivRef}>
          {allMessages?.length > 0 &&
            allMessages?.map((item, index) => {
              return (
                <>
                  {item.isUser ? (
                    <>
                      {index === 0 ? null : (
                        <UserResponse response={item.message} />
                      )}
                    </>
                  ) : (
                    <BotResponse response={item.message} />
                  )}
                </>
              );
            })}
          {isWriting && <BotResponse isWriting />}
        </StyledCont>
        <BottomStyled>
          <StyledInput
            handleSendMessage={(event) => handleSendMessage(event)}
            handleChange={(event) => handleChange(event)}
            setMessage={setMessage}
            message={message}
          />
        </BottomStyled>
      </StyledContainer>
      <Styledicon onClick={() => setOpen(true)}>
        <img alt='' src={Icon} />
      </Styledicon>
    </>
  );
};

export default Chatbot;
