import React from 'react';
import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
  CancelarButton,
} from '../../../styles/common.styles';

import { url } from '../../../api';

const warning = `${url}warnigSign.svg`;

const SelectAllModal = ({
  open,
  setOpen,
  setLoading,
  changeTasaItemsSelected,
  publish,
  valueFilters,
  tasa,
}) => {
  const handleApply = () => {
    setOpen(false);
    setLoading(true);
    changeTasaItemsSelected({
      variables: {
        inMarketplace: publish,
        date: valueFilters.date,
        idUac: valueFilters.idUac,
        idManufacture: valueFilters.idManufacture,
        isByFilters: true,
        tasa: tasa,
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
    setLoading(false);
  };
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Flex gap='16px' width='100%' justify='center' align='center'>
              <img alt='' src={warning} />
              <Text size='32px' weight='800' color='#0D3564' textAlign='center'>
                Todas las piezas están seleccionadas{' '}
              </Text>
            </Flex>
            <Flex gap='16px' direction='column' width='100%' align='center'>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                color='#14181A'
                width='100%'
                textAlign='left'
              >
                ¿Estás seguro que deseas aplicar la tasa de ganancia en todas?
              </Text>
            </Flex>
            <Flex align='center' gap='24px' justify='center'>
              <CancelarButton width='194px' onClick={handleCancel}>
                Cancelar
              </CancelarButton>
              <Button width='194px' onClick={handleApply}>
                Aplicar
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default SelectAllModal;
