import React, { useState } from 'react';
import { Button, Flex, Text } from '../../../../styles/common.styles';
import ModalDelete from '../../ModalDelete';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../../helpers/shopCart';

export default function ResumenCompra({
  setStepSelected,
  items,
  subTotal,
  countItems,
  costoEnvio,
  acceptedTerms,
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ModalDelete open={openModal} setOpen={setOpenModal} all />
      <Flex
        width='100%'
        direction='column'
        gap='40px'
        maxWidth='370px'
        minWidth='270px'
        mobile='max-width: 100%;'
      >
        <Text
          color='#004C97'
          width='100%'
          textAlign='center'
          size='24px'
          weight='700'
        >
          Resumen de compra
        </Text>
        <Flex
          padding='32px 16px'
          direction='column'
          extras='box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);'
          width='100%'
          mobile='padding: 32px;'
        >
          <Flex direction='row' width='100%' align='center' gap='8px'>
            <Text textAlign='left'> Subtotal ({countItems} productos):</Text>

            <span style={{ color: '#8299A3' }}>
              ${formatNumberWithTwoDecimals(subTotal)} MXN
            </span>
          </Flex>
          <Text textAlign='left' style={{ marginTop: '0' }}>
            Costo de envío:{' '}
            <span style={{ color: '#8299A3' }}>{costoEnvio} </span>
          </Text>
          <Flex direction='row' width='100%' align='center' gap='16px'>
            <Text
              textAlign='left'
              family='Roboto'
              size='24px'
              weight='700'
              color='#022741'
              style={{ marginTop: '48px', marginBottom: '48px' }}
            >
              Total:
              <span style={{ fontWeight: '500px' }}>
                {' '}
                &nbsp; $
                {formatNumberWithTwoDecimals(
                  subTotal + stringToNumber(costoEnvio)
                )}{' '}
                MXN
              </span>
            </Text>
          </Flex>
          <Flex
            direction='column'
            padding='0 25px'
            gap='16px'
            align='center'
            width='100%'
          >
            <Button
              disabled={countItems === 0 || acceptedTerms.includes(false)}
              width='100%'
              onClick={() => setStepSelected(3, 'parcel', {})}
            >
              Comprar todos
            </Button>
            <Button width='100%' secondary onClick={() => setOpenModal(true)}>
              Eliminar todos
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
