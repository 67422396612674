import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 100vw;
  width: 100%;
`;
export const SubContainer = styled.div`
  display: flex;
  background-color: #eff3f8;
`;
