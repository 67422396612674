import styled from 'styled-components';
import downArrow from '../../../assets/dropdownlightblue.svg';

export const MainContainer = styled.div`
  width: 100%;
  height: 482px;
  position: relative;
  @media (max-width: 1023px) {
    height: 724px;
    margin: 0 auto;
  }
`;

export const Bgcolor = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(13, 53, 100, 0) 22.43%,
    #0d3564 58.83%
  );
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 1023px) {
    background: linear-gradient(
      270deg,
      rgba(13, 53, 100, 0) 14.08%,
      #0d3564 55.87%
    );
    flex-shrink: 0;
  }
`;

export const Title = styled.h2`
  font-size: 4rem;
  font-weight: 900;
  line-height: 110%;
  color: #32b7d7;
  padding-top: 120px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 48px;
  font-family: 'Mulish', sans-serif;
  position: relative;
  @media (max-width: 1023px) {
    font-size: 3rem;
    text-align: center;
    padding-top: 120px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 48px;
  }
`;

export const TitleWhite = styled.span`
  font-size: 4rem;
  font-weight: 900;
  line-height: 110%;
  color: #fff;
  font-family: 'Mulish', sans-serif;
  position: relative;
  @media (max-width: 1023px) {
    font-size: 3rem;
    text-align: center;
  }
`;

export const FinderContainer = styled.div`
  display: flex;
  border-radius: 8px;
  height: 40px;
  margin-left: 80px;
  margin-right: 80px;
  width: 845px;
  background-color: #fff;
  gap: 8px;
  position: relative;
  padding-left: 8px;
  @media (max-width: 1023px) {
    flex-direction: column;
    height: fit-content;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    padding: 8px 16px;
  }
`;

export const ItemMenu = styled.div`
  width: 25%;

  @media (max-width: 1023px) {
    width: 100%;
  }
  ${({ clean2 }) =>
    clean2 &&
    `
    display: flex;
    align-items: center;
    justify-content:center;
    height: 40px;
    height: 100%;
     @media (min-width: 1024px) {
    display:none;
  }
  
  `}

  ${({ clean }) =>
    clean &&
    `
    display: flex;
    align-items: center;
    justify-content:center;
    height: 100%;
     @media (max-width: 1023px) {
    display:none;
  }
  
  `}
`;
export const Divisor = styled.div`
  width: 0;
  border-width: 1px;
  height: 70%;
  margin: auto 0;
  border-color: #32b7d7;
  border-style: solid;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileDivisor = styled.div`
  width: 100%;
  border-width: 1px;
  height: 0;
  margin: auto 0;
  border-color: #dcdede;
  border-style: solid;
  @media (min-width: 1024px) {
    display: none;
  }
`;
export const Selection = styled.select`
  padding: 0 40px 0 0;
  text-overflow: ellipsis;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  border-color: #fff;
  background-color: #fff;
  border-radius: 8px;
  color: #0d3564;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  outline: none;
  background: url(${downArrow}) no-repeat right center;
  background-size: 28px; /* Ajusta el tamaño de la flecha */
  @media (max-width: 1023px) {
    padding: 8px;
  }
`;

export const InputText = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-color: #fff;
  border-width: 0;
  color: #0d3564;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  &:focus {
    outline: none; /* Elimina el resaltado del borde cuando el input está enfocado */
  }
  @media (max-width: 1023px) {
    padding: 8px;
  }
`;
export const ButtonFinder = styled.button`
  width: 100%;
  height: 100%;
  background-color: #32b7d7;
  color: #fff;
  text-align: center;
  border-style: solid;
  border-color: #32b7d7;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  cursor: pointer;
  @media (max-width: 1023px) {
    border-radius: 8px;
    padding: 8px;
  }
`;
