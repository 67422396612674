import React from 'react';
//styles
//assets
import { url } from '../../../api';
import {
  Overlay,
  MainContainer,
  ButtonCargar,
  ButtonContainer,
  MainTitle,
  Subtitle,
} from './ModalLoSentimos.styles';
import { Flex } from '../../../styles/common.styles';
const edit = `${url}Editar.svg`;
const close = `${url}CloseButton.png`;
const warning = `${url}warnigSign.svg`;
export default function ModalLosentimos({
  showModalDelete,
  setShowModalDelete,
}) {
  return (
    <Overlay>
      <MainContainer>
        <Flex direction='row' align='center' gap='8px' justify='center'>
          <img alt='' src={warning} />
          <MainTitle>Lo sentimos </MainTitle>
        </Flex>
        <Subtitle>
          Se ha realizado una búsqueda en el sistema, pero lamentablemente,{' '}
          <span style={{ fontWeight: '800' }}>
            por el momento no se encuentran datos relacionados.
          </span>
        </Subtitle>
        <ButtonContainer>
          <ButtonCargar onClick={() => setShowModalDelete(!showModalDelete)}>
            Entendido
          </ButtonCargar>
        </ButtonContainer>
      </MainContainer>
    </Overlay>
  );
}
