import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 53px;
  padding: 32px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: var(--primaryocean, #0e79b4);
`;

export const InnerContaierPrincipal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
  margin-bottom: 30px;
`;
export const EscogeText = styled.h1`
  color: #004c97;
  text-align: center;
  /* DESKTOP/H3 */
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  @media (max-width: 1024px) {
    font-size: 28px;
  }
`;

export const ParaCada = styled.h2`
  color: #004c97;
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
`;

export const TextNormalTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--neutral-white, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  margin: 0;
  cursor: pointer;
`;

export const HiddenmobileText = styled.p`
  color: var(--neutral-white, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  margin: 0;
  cursor: pointer;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const TextBoldText = styled.p`
  color: var(--neutral-white, #fff);
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const InnerContaier = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: end;
`;

export const Desplegable = styled.div`
  display: flex;
  height: 53px;
  padding: 0px 32px;
  justify-content: space-between;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
  background-color: #eff3f8;
`;

export const DesplegableTextBold = styled.p`
  color: #32b7d7;

  /* DESKTOP/H5 */
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin: 0;
`;

export const DesplegableTextNormal = styled.p`
  color: #32b7d7;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin: 0;
`;

export const Subtotal = styled.div`
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  gap: 16px;
  background: #eff3f8;
`;

export const ContainerSubtotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
`;

export const SubtotalTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const EnvioContaier = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SubtotalText = styled.p`
  color: #022741;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;
export const Amount = styled.p`
  color: var(--neutral-night, #55646a);

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 170px;
  min-width: 170px;
`;

export const EnvioText = styled.p`
  color: var(--neutral-night, #55646a);
  text-align: right;

  /* COMMON/Body_Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;
export const Unselect = styled.p`
  color: var(--neutral-storm, #8299a3);
  text-align: right;
  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  min-width: 150px;
  width: 170px;
  min-width: 170px;
`;

export const SubtotalAmountContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 12px;
`;

export const SubtotalAmount = styled.p`
  color: var(--primaryocean, #0e79b4);
  /* COMMON/Title */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  width: 170px;
  min-width: 170px;
  text-align: right;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonEliminar = styled.button`
  display: flex;
  width: 218px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #fff);
  color: var(--primarydeep, #0d3564);
  cursor: pointer;

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
