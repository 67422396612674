/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { REGISTER_MARKETPLACE_USER } from '../../../src/graphql/Mutation';
import { GET_COLONY_BY_CP } from '../../graphql/Query';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import {
  Background,
  Flex,
  GeneralContainer,
  Text,
  StyledInput,
  StyledPassword,
  Button,
  StyledSelect,
  AlertModal,
  StyledTextArea,
} from '../styles/common.styles';
import { url } from '../api';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import NewAccountModal from '../components/common/NewAccountModal';
import Loader from '../components/common/Loader';

const BgMobile = `${url}JPG/createAccountbgmobile.jpg`;
const Bg = `${url}JPG/createAccountbg.jpg`;
const personalDataIcono = `${url}personalDataIcon.svg`;
const contactoDataIcon = `${url}contactDataIcon.svg`;
const seguidadIcon = `${url}SecurityIcon.svg`;
const ubicacionicon = `${url}ubicacion.svg`;

const NewAccount = () => {
  const [validPassword, setValidPassword] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [validTel, setValidTel] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [gralData, setGralData] = useState(false);
  const [continueButton, setContinueButton] = useState(false);
  const [validPostalCode, setValidPostalCode] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordErrorConfirm, setPasswordErrorConfirm] = useState('');
  const [validForm, setValidForm] = useState({
    firstName: '',
    secondName: '',
    fatherLastName: '',
    motherLastName: '',
    password: '',
    passwordConfirm: '',
    email: '',
    phone: '',
    birhday: '',
    genre: '',
    country: '',
    address: '',
    numExt: '',
    numInt: '',
    cp: '',
    instrDelivery: '',
    division: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [secondnameError, setSecondNameError] = useState('');
  const [fatherLastNameError, setFatherLastNameError] = useState('');
  const [motherLastNameError, setMotherLastNameError] = useState('');
  const [numExtError, setNumExtError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [numError, setNumError] = useState('');
  const [cpError, setCpError] = useState('');
  const [isNameAndLastNameValid, setIsNameAndLastNameValid] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);

  const validateNameAndLastName = () => {
    const nameContainsNumber =
      /[0-9]/.test(validForm.firstName) || /[0-9]/.test(validForm.secondName);
    const lastNameContainsNumber =
      /[0-9]/.test(validForm.fatherLastName) ||
      /[0-9]/.test(validForm.motherLastName);

    // Si alguno de los campos de nombre o apellidos contiene números, la validación falla
    if (nameContainsNumber || lastNameContainsNumber) {
      setIsNameAndLastNameValid(false);
    } else {
      setIsNameAndLastNameValid(true);
    }
  };

  const [colonies, setColonies] = useState([]);
  const [getColony] = useLazyQuery(GET_COLONY_BY_CP, {
    fetchPolicy: 'no-cache',
    onCompleted({ getColony }) {
      const parsedInfo = JSON.parse(getColony.response);
      setColonies(parsedInfo);
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    validateNameAndLastName();
  }, [
    validForm.firstName,
    validForm.secondName,
    validForm.fatherLastName,
    validForm.motherLastName,
  ]);

  // Función para actualizar el estado con el ancho de pantalla actual
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Agregar el evento 'resize' cuando el componente se monta
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const handleValidation = (type, e) => {
    if (type === 'tel') {
      const value = e;
      setValidForm({ ...validForm, phone: value });
      const regex = /^[0-9]{12}$/;
      if (regex.test(value) === true) {
        setValidTel(true);
        setNumError('');
      } else {
        setValidTel(false);
        setNumError('Ingrese un número de celular valido');
      }
    }
    if (type === 'email') {
      const value = e.target.value;
      setValidForm({ ...validForm, email: value });
      const regex =
        /^[A-Za-z0-9+]+(?:[._-][A-Za-z0-9]+)*@[A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;

      if (regex.test(value) === true) {
        setValidEmail(true);
        setEmailError(''); // Borra el mensaje de error si el correo es válido
      } else {
        setValidEmail(false);
        setEmailError('Ingrese un correo electrónico válido');
      }
    }
    if (type === 'password') {
    }
    if (type === 'postalCode') {
      const value = e.target.value;
      setValidForm({ ...validForm, postalCode: value });
      const regex = /^[0-9]{5}$/;
      if (regex.test(value) === true) {
        setValidPostalCode(true);
      } else {
        setValidPostalCode(false);
        setColonies([]);
      }
    }
  };

  const checkFormCompleteness = () => {
    const {
      firstName,
      secondName,
      fatherLastName,
      motherLastName,
      password,
      passwordConfirm,
      email,
      phone,
      birhday,
      country,
      address,
      numExt,
      numInt,
      cp,
      instrDelivery,
      division,
    } = validForm;

    const isComplete =
      firstName !== '' &&
      fatherLastName !== '' &&
      motherLastName !== '' &&
      password !== '' &&
      passwordConfirm !== '' &&
      email !== '' &&
      phone !== '' &&
      birhday !== '' &&
      address !== '' &&
      numExt !== '' &&
      cp !== '' &&
      division !== '';

    setIsFormComplete(isComplete);
  };

  useEffect(() => {
    setValidPostalCode(true);
    checkFormCompleteness();
  }, [validForm]);

  useEffect(() => {
    if (validForm.cp.length === 5) {
      getColony({
        variables: {
          cp: validForm.cp,
        },
      });
    } else if (validForm.cp.length >= 4) {
      setColonies([]);
    }
  }, [validForm.cp]);

  // Función de validación genérica para campos de nombre
  const validateName = (fieldName, value) => {
    if (!/^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/.test(value)) {
      switch (fieldName) {
        case 'firstName':
          setNameError('Solo se permiten letras en el Primer Nombre');
          break;
        case 'secondName':
          setSecondNameError('Solo se permiten letras en el segundo Nombre');
          // Puedes agregar lógica similar para otros campos de nombre si es necesario
          // setNameError('Mensaje de error para el segundo nombre');
          break;
        case 'fatherLastName':
          // Puedes agregar lógica similar para otros campos de apellido paterno si es necesario
          setFatherLastNameError(
            'Solo se permiten letras en el primer apellido'
          );
          break;
        case 'motherLastName':
          // Puedes agregar lógica similar para otros campos de apellido materno si es necesario
          setMotherLastNameError(
            'Solo se permiten letras en el apellido materno'
          );
          break;
        default:
          break;
      }
      return false;
    } else {
      // Si el valor es válido, borra el mensaje de error correspondiente
      switch (fieldName) {
        case 'firstName':
          setNameError('');
          break;
        case 'secondName':
          // Borra el mensaje de error para el segundo nombre si es necesario
          setSecondNameError('');
          break;
        case 'fatherLastName':
          // Borra el mensaje de error para el apellido paterno si es necesario
          setFatherLastNameError('');
          break;
        case 'motherLastName':
          // Borra el mensaje de error para el apellido materno si es necesario
          setMotherLastNameError('');
          break;
        default:
          break;
      }
      return true;
    }
  };

  const [createUser] = useMutation(REGISTER_MARKETPLACE_USER, {
    onCompleted: (data) => {
      console.log(data);
      const response = data.registerMarketplaceUser;
      if (response === false) {
        setModal(false);
        // alert(`Correo electrónico ya registrado`);
        setModal2(true);
      } else setModal(true);
    },
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  const [tryContinue, setTryContinue] = useState(false);

  const handleContinue = () => {
    if (
      !isFormComplete ||
      isLoading ||
      !isNameAndLastNameValid ||
      !validEmail ||
      passwordErrorConfirm ||
      !validTel
    ) {
      console.log('probanding');
      setTryContinue(true);
    } else {
      setIsLoading(true);
      createUser({
        variables: {
          email: validForm.email,
          password: validForm.password,
          name: validForm.firstName,
          phone: validForm.phone,
          last_name: validForm.fatherLastName,
          surname: validForm.secondName,
          surname2: validForm.motherLastName,
          gender: validForm.genre,
          birthdate: validForm.birhday,
          country: 'México',
          street: validForm.address,
          division: validForm.division,
          extNUm: validForm.numExt,
          inteNum: validForm.numInt,
          zip: validForm.cp,
          reference: validForm.instrDelivery,
          priorityAdress: true,
        },
      })
        .then(() => {
          setIsLoading(false); // Stop loading when request is successful
        })
        .catch((error) => {
          setIsLoading(false); // Stop loading on error
          console.error('Error:', error);
        });
      // setModal(true);}
    }
  };

  useEffect(() => {
    if (validForm.password === validForm.passwordConfirm) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  }, [validForm]);

  useEffect(() => {
    if (
      validEmail &&
      validTel &&
      validForm.firstName != '' &&
      validForm.fatherLastName != '' &&
      validForm.motherLastName != '' &&
      validForm.password != '' &&
      validForm.passwordConfirm != '' &&
      validPassword
    ) {
      setContinueButton(true);
    } else {
      setContinueButton(false);
    }
  }, [validEmail, validTel, validPassword, validForm]);

  const validatePassword = (password) => {
    // Password requirements: at least 8 characters, one uppercase, one lowercase, one number, and one special character
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+#.])[A-Za-z\d@$!%*?&+#.]{8,}$/;

    if (passwordRegex.test(password)) {
      setValidPassword(true);
      setPasswordError('');
    } else {
      setValidPassword(false);
      setPasswordError(
        'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial.'
      );
    }
  };

  useEffect(() => {
    // Call the password validation function when the password changes
    validatePassword(validForm.password);
  }, [validForm.password]);

  useEffect(() => {
    if (validForm.password === validForm.passwordConfirm) {
      setValidPassword(true);
      setPasswordErrorConfirm('');
    } else {
      setValidPassword(false);
      setPasswordErrorConfirm('Las contraseñas no coinciden.');
    }
  }, [validForm.password, validForm.passwordConfirm]);

  return (
    <>
      <AlertModal
        subtitle='Correo electrónico ya registrado'
        buttonOnClick={() => setModal2(false)}
        buttonText={'Cerrar'}
        open={modal2}
      />
      <Nav />
      <Loader open={isLoading} />
      <GeneralContainer>
        <NewAccountModal open={modal} validForm={validForm} />
        {/*  Banner start */}
        <>
          <Flex
            width='100%'
            maxHeight='208px'
            height='208px'
            padding='40px 80px'
            align='center'
            justify='center'
            gap='32px'
            direction='column'
            style={{
              position: 'relative',
            }}
            mobile='height:fit-content; padding: 80px 24px; gap: 32px; max-height:100%;'
          >
            <Background
              style={{
                height: '100%',
                width: '100%',
                backgroundSize: 'cover', // Adjust this property
                backgroundPosition: 'center', // Adjust this property
              }}
            >
              <img
                src={screenWidth <= 1023 ? BgMobile : Bg}
                alt='Background'
                style={{
                  width: '100%', // Set the image width to 100% to fit the container
                  height: '100%', // Set the image height to 100% to fit the container
                  objectFit: 'cover', // Ensure the image covers the entire container
                }}
              />
            </Background>
            <Flex
              align='center'
              direction='row'
              mobile='flex-direction:column;'
            >
              <Text size='48px' weight='900' color='#32B7D7' textAlign='center'>
                Crea tu cuenta&nbsp;
              </Text>

              <Text size='48px' weight='900' color='#ffffff' textAlign='center'>
                en Ballena
              </Text>
            </Flex>
            <Text size='24px' weight='800' color='#ffffff' textAlign='center'>
              ¡Crea tu cuenta en solo unos minutos! Proporciona algunos datos y
              estarás listo para empezar.
            </Text>
          </Flex>
        </>
        {/*  Banner end */}
        {/*  Form start */}
        <>
          <Flex
            //Form Container
            width='100%'
            padding='80px'
            gap='56px'
            direction='column'
            mobile='padding: 80px 24px;'
            align='center'
          >
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='32px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={personalDataIcono} alt='' />
                <Text size='28px' weight='800' color='#0D3564'>
                  Datos personales
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Primer nombre
                  </Text>
                  <StyledInput
                    type='firstName'
                    placeholder='Ingresa tu primer nombre'
                    onKeyDown={(e) => {
                      if (/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(e.key)) {
                        e.preventDefault(); // Evita que el carácter se ingrese en el campo
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValidForm({ ...validForm, firstName: value });
                      validateName('firstName', value);
                    }}
                    error={tryContinue && validForm.firstName == ''}
                  />
                  {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                </Flex>

                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Segundo nombre (opcional)
                  </Text>
                  <StyledInput
                    type='firstName'
                    placeholder='Ingresa tu segundo nombre'
                    onKeyDown={(e) => {
                      if (/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(e.key)) {
                        e.preventDefault(); // Evita que el carácter se ingrese en el campo
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValidForm({ ...validForm, secondName: value });
                      validateName('secondName', value);
                    }}
                  />
                  {secondnameError && (
                    <p style={{ color: 'red' }}>{secondnameError}</p>
                  )}
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Apellido paterno
                  </Text>
                  <StyledInput
                    type='fatherLastName'
                    placeholder='Ingresa tu apellido paterno'
                    onKeyDown={(e) => {
                      if (/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(e.key)) {
                        e.preventDefault(); // Evita que el carácter se ingrese en el campo
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValidForm({ ...validForm, fatherLastName: value });
                      validateName('fatherLastName', value);
                    }}
                    error={tryContinue && validForm.fatherLastName == ''}
                  />
                  {fatherLastNameError && (
                    <p style={{ color: 'red' }}>{fatherLastNameError}</p>
                  )}
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Apellido materno
                  </Text>
                  <StyledInput
                    type='motherLastName'
                    placeholder='Ingresa tu apellido materno'
                    onKeyDown={(e) => {
                      if (/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(e.key)) {
                        e.preventDefault(); // Evita que el carácter se ingrese en el campo
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValidForm({ ...validForm, motherLastName: value });
                      validateName('motherLastName', value);
                    }}
                    error={tryContinue && validForm.motherLastName == ''}
                  />
                  {motherLastNameError && (
                    <p style={{ color: 'red' }}>{motherLastNameError}</p>
                  )}
                </Flex>
              </Flex>

              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Fecha de nacimiento
                  </Text>
                  <StyledInput
                    type='date'
                    max='9999-12-31'
                    placeholder='Ingresa tu fecha de nacimiento'
                    onChange={(e) =>
                      setValidForm({ ...validForm, birhday: e.target.value })
                    }
                    error={tryContinue && validForm.birhday == ''}
                  />
                  {/* {validForm.birhday ? <p></p> : <p style={{color:'red'}}>Ingrese una fecha de nacimiento</p>} */}
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Género
                  </Text>
                  <StyledSelect
                    error={tryContinue && validForm.genre == ''}
                    value={validForm.genre}
                    onChange={(e) =>
                      setValidForm({ ...validForm, genre: e.target.value })
                    }
                  >
                    <option>Masculino</option>
                    <option>Femenino</option>
                  </StyledSelect>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='32px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={contactoDataIcon} alt='' />
                <Text size='28px' weight='800' color='#0D3564'>
                  Datos de contacto
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Correo electrónico
                  </Text>
                  <StyledInput
                    type='email'
                    placeholder='Ingresa tu correo electrónico'
                    onChange={(e) => handleValidation('email', e)}
                    error={tryContinue && validForm.email == ''}
                  />
                  {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número celular
                  </Text>
                  {/* //! select Number
                  <StyledInput
                    type='text'
                    placeholder='Ingresa tu segundo nombre'
                  /> */}
                  <PhoneInput
                    country='mx'
                    containerStyle={{ width: '100%' }}
                    inputStyle={{
                      width: '100%',
                      height: '48px',
                      borderRadius: '8px',
                      border:
                        validForm.phone == '' && tryContinue
                          ? '1px solid #D22121'
                          : '1px solid #b9cad0',
                      boxShadow: 'none',
                    }}
                    onChange={(e) => handleValidation('tel', e)}
                    isValid={
                      validForm.phone == '' && tryContinue ? false : true
                    }
                  />
                  {numError && <p style={{ color: 'red' }}>{numError}</p>}
                </Flex>
              </Flex>
            </Flex>

            {/*  */}

            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='32px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={ubicacionicon} alt='' />
                <Text size='28px' weight='800' color='#0D3564'>
                  Domicilio
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Código Postal
                  </Text>
                  <StyledInput
                    type='text' // Cambiar el tipo a 'text'
                    placeholder='Ej: 01480'
                    required
                    maxLength={5}
                    onChange={(e) => {
                      const value = e.target.value;
                      const numericValue = value.replace(/\D/g, ''); // Eliminar caracteres no numéricos

                      if (numericValue.length <= 5) {
                        console.log('funcionando');
                        setValidForm({
                          ...validForm,
                          cp: numericValue,
                        });
                        setCpError('');
                      } else {
                        setValidForm({
                          ...validForm,
                          cp: '',
                        });
                        setCpError(
                          'El código postal debe tener exactamente 5 dígitos y contener solo números'
                        );
                      }
                    }}
                    value={validForm.cp}
                    error={tryContinue && validForm.cp == ''}
                  />
                  {cpError && <p style={{ color: 'red' }}>{cpError}</p>}
                </Flex>

                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    País o región
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='México'
                    /* onChange={(e) =>
                      setValidForm({ ...validForm, country: e.target.value })
                    } */
                    disabled
                  />
                </Flex>
              </Flex>

              {/* ----------------------------------------- */}
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Colonia
                  </Text>
                  <StyledSelect
                    error={tryContinue && validForm.division == ''}
                    value={validForm.division}
                    onChange={(e) =>
                      setValidForm({ ...validForm, division: e.target.value })
                    }
                  >
                    <option value='' disabled selected>
                      Selecciona tu colonia
                    </option>
                    {colonies?.length &&
                      colonies?.map((item) => {
                        return (
                          <option
                            key={item.settlement_name}
                            value={item.settlement_name}
                          >
                            {item.settlement_name}
                          </option>
                        );
                      })}
                  </StyledSelect>
                </Flex>

                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Calle
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa el nombre de tu calle'
                    onChange={(e) =>
                      setValidForm({ ...validForm, address: e.target.value })
                    }
                    error={tryContinue && validForm.address == ''}
                  />
                </Flex>
              </Flex>
              {/* ----------------------------------------- */}
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número Exterior
                  </Text>
                  <StyledInput
                    error={tryContinue && validForm.numExt == ''}
                    type='number'
                    required
                    placeholder='Ingresa el número exterior de tu domicilio'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        setValidForm({
                          ...validForm,
                          numExt: value,
                        });
                        setNumExtError('');
                      } else {
                        setValidForm({
                          ...validForm,
                          numExt: '',
                        });
                        setNumExtError('Este campo solo admite números');
                      }
                    }}
                  />
                  {numExtError && <p style={{ color: 'red' }}>{numExtError}</p>}
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número interior (opcional)
                  </Text>
                  <StyledInput
                    type='text'
                    placeholder='Ingresa el número interior de tu domicilio'
                    onChange={(e) =>
                      setValidForm({
                        ...validForm,
                        numInt: e.target.value,
                      })
                    }
                  />
                </Flex>
              </Flex>

              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              ></Flex>
              <Flex
                //Input container
                direction='column'
                gap='4px'
                width='100%'
              >
                <Text family='Inter' size='12px' weight='400'>
                  Instrucciones de entrega
                </Text>
                <StyledTextArea
                  type='text'
                  placeholder='Escribe alguna instrucción o referencia de tu domicilio'
                  maxLength={200}
                  onChange={(e) =>
                    setValidForm({
                      ...validForm,
                      instrDelivery: e.target.value,
                    })
                  }
                />
              </Flex>
            </Flex>

            {/*  */}

            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                //Title container
                width='100%'
                gap='32px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={seguidadIcon} />
                <Text size='28px' weight='800' color='#0D3564'>
                  Seguridad
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Contraseña
                  </Text>
                  <StyledPassword
                    error={tryContinue && validForm.password == ''}
                    placeholder={'Ingresa tu contraseña'}
                    handleChange={(e) => {
                      setValidForm({ ...validForm, password: e.target.value });
                      setPasswordError(''); // Clear the error message when input changes
                    }}
                    onFocus={() => setPasswordError('')} // Clear the error message on input focus
                  />
                  {passwordError && validForm.password.length > 0 && (
                    <p style={{ color: 'red' }}>{passwordError}</p>
                  )}
                </Flex>
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Confirma la contraseña
                  </Text>

                  <StyledPassword
                    error={tryContinue && validForm.passwordConfirm == ''}
                    placeholder={'Ingresa de nuevo tu contraseña'}
                    handleChange={(e) => {
                      setValidForm({
                        ...validForm,
                        passwordConfirm: e.target.value,
                      });
                      setPasswordErrorConfirm(''); // Clear the error message when input changes
                    }}
                    onFocus={() => setPasswordErrorConfirm('')} // Clear the error message on input focus
                  />
                  {passwordErrorConfirm &&
                    validForm.passwordConfirm.length > 0 && (
                      <p style={{ color: 'red' }}>{passwordErrorConfirm}</p>
                    )}
                </Flex>
              </Flex>
            </Flex>
            <Flex width='100%' direction='row' align='center' justify='center'>
              <Text family='Inter' size='12px' weight='400'>
                Al hacer clic en "Crear cuenta", declaro que leí{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Politicas de privacidad
                </span>{' '}
                y los{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Terminos y condiciones
                </span>{' '}
              </Text>
            </Flex>
            <Button
              mobile='width:100%;'
              onClick={() =>
                // isFormComplete &&
                !isLoading ? handleContinue() : null
              }
            >
              Crear cuenta
            </Button>
          </Flex>
        </>
        {/*  Form end */}
      </GeneralContainer>
      <Footer />
    </>
  );
};

export default NewAccount;
