/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
  GET_AUTOPARTS_BY_CATEGORY,
  GET_ALL_CATEGORIES,
} from '../../../graphql/Query';
import Loader from '../common/Loader';
import { Flex, MarketIcon, Text } from '../../styles/common.styles';
import { url } from '../../api';
import styled from 'styled-components';
const goArrow = `${url}goArrow.svg`;
const lineasBlancas = `${url}lineasblancas.png`;
const noAvailable = `${url}noAvailable.svg`;

const GridMapeableItem = ({ itemName, itemImg, itemId, handleClick }) => {
  return (
    <GridItem
      onClick={(e) => handleClick(itemId, itemName)}
      key={itemName}
      id={itemId}
    >
      <LineasBlancas src={lineasBlancas} />
      <Text
        textAlign='center'
        size='32px'
        weight='400'
        color='#ffffff'
        mobile='font-size: 16px; font-weight: 700;'
      >
        {itemName}
      </Text>
      <MarketIcon alt='' src={itemImg || noAvailable} />
      <img
        alt=''
        src={goArrow}
        style={{
          height: '32px',
          width: 'auto',
          alignSelf: 'flex-end',
        }}
      />
    </GridItem>
  );
};

const MarketOptions = ({ reloadData, setPages, setTotalResults, page }) => {
  const [listCategories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);



  useQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCategoriesLp }) => {
      const parsed = JSON.parse(getCategoriesLp.response);
      const categorias = parsed.shift();
      setCategories(parsed);
    },
  });

  const [searchByCategory] = useLazyQuery(GET_AUTOPARTS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      reloadData(data.getAutopartsByCategory.data);
      setPages(data.getAutopartsByCategory.totalPaginas);
      setTotalResults(data.getAutopartsByCategory.totalregistros);
    },
  });

  const handleClick = (id, name) => {
    searchByCategory({
      variables: {
        categoryId: id,
        page: page,
        itemsPerPage: 12,
      },
    });
    sessionStorage.setItem('Ctry', name);
    setLoading(true);
  };

  return (
    <>
      <Loader open={loading} />
      <Flex
        direction='column'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        padding='16px'
        gap='40px'
      >
        <Text
          textAlign='center'
          size='40px'
          weight='800'
          color='#004C97'
          mobile='font-size: 32px;'
        >
          Todo nuestro catálogo
        </Text>
        <Divider />
        <GridContainer>
          {listCategories.map((item) => (
            <GridMapeableItem
              key={item.id_Categoria}
              itemName={item.Categorias}
              itemImg={item.icon}
              itemId={item.id_Categoria}
              handleClick={handleClick}
            />
          ))}
        </GridContainer>
      </Flex>
    </>
  );
};

export default MarketOptions;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 302px));
  gap: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 302px));
  }
`;

const GridItem = styled.div`
  position: relative;
  max-width: 302px;
  width: 100%;
  height: 314px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 1023px) {
    height: 220px;
  }

  &:nth-child(4n + 1) {
    background-color: #0d3564;
  }

  &:nth-child(4n + 2) {
    background-color: #ed882b;
  }

  &:nth-child(4n + 3) {
    background-color: #0e79b4;
  }

  &:nth-child(4n + 4) {
    background-color: #32b7d7;
  }
`;

const LineasBlancas = styled.img`
  position: absolute;
  width: 100%;
  opacity: 100%;
  bottom: 0;
  overflow: hidden;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgb(166, 166, 166);
`;
